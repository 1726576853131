import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Form, Alert } from "Components";
import { useFetchApi } from "CustomHooks";

interface DynamicFormProps {
  formId?: number;
  hideFormName?: boolean;
  hideCompletionBar?: boolean;
}

const DynamicForm: React.FC<DynamicFormProps> = ({ formId, hideFormName = false, hideCompletionBar = false }) => {
  const params = useParams();

  const formCode = formId ? formId.toString() : params.formCode;
  const fetchApi = useFetchApi();
  const [formData, setFormData] = useState<any>(null);
  const [msg, setMsg] = useState<string>("");
  const [msgCode, setMsgCode] = useState<0 | 1 | 2 | 3 | 4>(0);

  useEffect(() => {
    if (!formCode) {
      setMsg("No formCode provided.");
      setMsgCode(1);
      return;
    }

    console.log(`Fetching form - ID: ${formCode}`);

    const fetchFormData = async () => {
      try {
        const response = await fetchApi("forms/goGetBarry", {
          formCode: parseInt(formCode, 10),
          // version: parseInt(version, 10),
        });
        if (response.status === 200) {
          setFormData(response.responseData);
        } else {
          console.warn("Non-200 response:", response.status);
          setMsg("Error fetching form data.");
          setMsgCode(1);
        }
      } catch (error) {
        console.error("Fetch error:", error);
        setMsg("Error fetching form data.");
        setMsgCode(1);
      }
    };
    fetchFormData();
  }, [formCode]);

  if (!formData) {
    return (
      <div>
        <Alert msg={msg} msgCode={msgCode} />
        <div>Loading form...</div>
      </div>
    );
  }

  return (
    <div>
      <Alert msg={msg} msgCode={msgCode} />
      <Form formData={formData} hideFormName={hideFormName} hideCompletionBar={hideCompletionBar}/>
    </div>
  );
};

export default DynamicForm;
