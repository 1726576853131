import { useSelector } from "react-redux";

// Define the union type for all valid roles
type Role = 
  | "Developer"
  | "QA"
  | "Internal Auditor"
  | "External Auditor"
  | "Moderator"
  | "Validator"
  | "Content Expert"
  | "Candidate"
  | "First Aid Officer"
  | "WHS Officer"
  | "Trainer"
  | "Super User"
  | "User"
  | "Active Trainer"
  | "IT Support"
  | "Admin"
  | "Accounts Officer"
  | "Compliance Officer";

// Case insesnitive lookup for roles: stops errors when numpties gave us crap data
const roleLookup: Record<string, Role> = Object.fromEntries(
  ([
    "Developer", "QA", "Internal Auditor", "External Auditor", "Moderator",
    "Validator", "Content Expert", "Candidate", "First Aid Officer",
    "WHS Officer", "Trainer", "Super User", "User", "Active Trainer",
    "IT Support", "Admin", "Accounts Officer", "Compliance Officer"
  ] as Role[]).map(role => [role.toLowerCase(), role])
);

export const useHasRole = (roles: string[]) => {
  return useSelector((state: { user?: { roles?: Role[] } }) => {
    const userRoles = state.user?.roles || [];

    // Normalize user roles for case-insensitive lookup
    const userRolesSet = new Set(userRoles.map(role => role.toLowerCase()));

    return roles.some(role => {
      const normalizedRole = roleLookup[role.toLowerCase()];
      return normalizedRole ? userRolesSet.has(normalizedRole.toLowerCase()) : false;
    });
  });
};
