// TO DO: Need to come back here and sort the typing 
import { fetchStudentHistory } from "./FetchStudentHistory";

interface UserData {
  role_id: string;
}

interface FetchApi {
}

interface FetchResult {
  success: boolean;
  data?: any; 
  msg?: string;
}

export const fetchUser = async (
  data: UserData,
  fetchApi: FetchApi,
  setUserData: (data: UserData) => void,
  setStudentHistoryData: (data: any) => void
): Promise<{ success: boolean; error?: any }> => {
  try {
    setUserData(data); // Also sets logged in

    const result: FetchResult = await fetchStudentHistory(data.role_id, fetchApi);

    if (result.success) {
      setStudentHistoryData(result.data);
    } else {
      console.error("Error fetching student history:", result.msg);
    }

    return { success: true };
  } catch (error) {
    console.error("Unexpected error fetching user data:", error);
    return { success: false, error };
  }
};
