import Cookies from 'js-cookie';

const setCookie = (key: string, value: string, expiresInDays = 1 ) => {
  Cookies.set(key, value, { expires: expiresInDays, secure: true, sameSite: "Strict" });
};

const getCookie = (key: string) => {
  return Cookies.get(key);
};

const removeCookie = (key: string) => {
  Cookies.remove(key);
};

export { setCookie, getCookie, removeCookie };