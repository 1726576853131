import {useLocation} from 'react-router-dom';
import logError from '../Services/Logger';

const environment = process.env.REACT_APP_ENV;

const useFetchApi = () => {

    const location = useLocation();

    const fetchApi = async (endpoint, postData) => {


        // const errorLog = async (errorDeets, msg) => {
        //     try {
        //         const response = await logError({ errorDeets });
        //         return msg + ` See event log ${response.insertedId} for more details.`;
        //     } catch (err) {
        //         console.error('Task failed and Error logging failed:', err);
        //         throw err; // Re-throw the error if needed for further handling
        //     }
        // };

        const errorLog = async (errorDeets, msg) => {
            try {
                const response = await logError({ errorDeets });

                if (response.insertedId) {
                    return msg + ` See event log ${response.insertedId} for more details.`;
                }

                return msg; // Return the original message if no event log ID is available
            } catch (err) {
                console.error('Task failed and Error logging failed:', err);
                throw err; // Re-throw the error if needed for further handling
            }
        };

        let api;
        switch(environment) {
            case 'staging':
                api = 'https://api.trainingprofessionals.com.au/';
                break;
            case 'production':
                api = 'https://api.tp.edu.au/';
                break;
            case 'development':
                api = 'https://10.1.10.49:5001/';
                break;
            default:
                api = 'https://localhost:3001/';
        }

        const requestBody = JSON.stringify(postData);
        const destination = api + endpoint;

        let msg;
        let msgCode;
        let responseData;
        let status;

        await fetch(destination, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: requestBody
        })

            .then(response => response.json().then(async data => {

                msg = data.message;
                responseData = data;
                status = response.status;

                const errorDeets = {
                    page: location.pathname,
                    dataSubmitted: requestBody,
                    errorCode: response.status,
                    errorDesc: msg,
                    endpoint: endpoint,
                };

                switch (true) {

                    // Handle the incredible weight of Success
                    case (response.status >= 200 && response.status < 300):
                        msgCode = 1;
                        break;

                    // Handle Warnings
                    case (response.status >= 300 && response.status < 400):
                        msgCode = 2;
                        break;

                    // Handle Client Errors
                    case (response.status >= 400 && response.status < 500):
                        msgCode = 3;
                        msg = await errorLog(errorDeets, msg);
                        break;

                    // Handle Server Errors
                    case (response.status >= 500 && response.status < 600):
                        msgCode = 4;
                        msg = await errorLog(errorDeets, msg);
                        break;

                    default:
                        errorDeets.errorDesc = 'Failed to fetch data or fetch returned a response code that was not recognised as a catchable case';
                        msg = await errorLog(errorDeets, msg);
                        console.log('Fetch Failed. Server responded with:', response);
                        break;

                }

            }))

            .catch(error => {
                logError({
                    // page: location.pathname,
                    dataSubmitted: requestBody,
                    errorData: error.data,
                    errorCode: error.errorCode,
                    errorStack: error.stack,
                    errorMsg: error.message,
                    errorDesc: 'Failed to fetch request.', // Detailed description of the error
                    endpoint: endpoint,
                }).then(response => {
                    console.log('Server responded with:', response);
                    msg = `Endpoint communication failed. Event ID: ${response.errorLogId}`; // Example of using the response
                    msgCode = 4;
                }).catch(err => {
                    console.error('Error logging failed:', err);
                    msg = 'Failed to communicate with server';
                    msgCode = 4;
                });
            });
            // console.log('msgCode:',msgCode);
        return { msg, msgCode, status, responseData };

    };

    return fetchApi;
};


export default useFetchApi;



// =============================== To Do =================================== //



// =============================== Bugs =================================== //