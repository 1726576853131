import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConstLink } from "../ConstLink";
import { useUserField, useLogout } from "CustomHooks";

import "./Styles/footerMenu.scss";

function FooterMenu() {
  const navigate = useNavigate();
  const isLoggedIn = useUserField("loggedIn");
  const accessLevel = useUserField("accessLevel");
  const isTrainer = accessLevel !== null && accessLevel > 3;
  const { logout } = useLogout();
  const [showMenu, setShowMenu] = useState(false);

  const { closeHeaderMenu } = useContext(ConstLink);

  function toggleFooterMenu() {
    setShowMenu(!showMenu);
  
    const overlay = document.querySelector(".overlay");
    const menuItems = document.querySelectorAll<HTMLElement>(".footer-menu-item");
    const pageContent = document.querySelector(".page-content");
  
    const isOpen = overlay?.classList.contains("open");
  
    if (isOpen) {
      // If closing, reset the styles to the initial state
      overlay?.classList.remove("open");
      menuItems.forEach((item) => {
        (item as HTMLElement).style.animation = "none"; // Reset the animation
        (item as HTMLElement).style.opacity = "0"; // Reset opacity
        (item as HTMLElement).style.transform = "translateY(100px)"; // Reset position
      });
      pageContent?.classList.remove("open-menu");
    } else {
      // If opening, apply the staggered animation to each menu item
      closeHeaderMenu();
      overlay?.classList.add("open");
      pageContent?.classList.add("open-menu");
  
      menuItems.forEach((item, index) => {
        (item as HTMLElement).style.animation = "none"; // Reset animation first to allow replay
        setTimeout(() => {
          (item as HTMLElement).style.animation = `slideIn 0.4s ease-in-out forwards`;
          (item as HTMLElement).style.animationDelay = `${index * 0.1}s`; // Staggered delay
        }, 50); // Small delay to ensure reset happens properly before reapplying animation
      });
    }
  }
  
  const navigateTo = (address: string) => {
    toggleFooterMenu();
    navigate(address);
  };
  
  const handleLogout = () => {
    logout();
  };
  

  return (
    <div>
      <div className="overlay">
       {isLoggedIn ?
          <div className="footer-menu-items">
            <div className='footer-menu-item' onClick={() => navigateTo("/")}>Go Home</div>
            <div className='footer-menu-item' onClick={() => navigateTo("/study/diary")}>Diary</div>
            <div className='footer-menu-item' onClick={() => navigateTo("/study/my-profile")}>My Profile</div>
            <div className='footer-menu-item' onClick={() => navigateTo("/contact")}>Contact</div>
            {isTrainer && <div className='footer-menu-item' onClick={() => window.open("https://tp.edu.au/portal", "_blank")}>Legacy Portal</div>}
            {isTrainer && <div className='footer-menu-item' onClick={() => window.open("https://new.tp.edu.au/portal", "_blank")}>Student Portal</div>}
              <div className='footer-menu-item' onClick={() => handleLogout()}>Sign Out</div>
          </div>
              :  
          <div className="footer-menu-items">
            <div className='footer-menu-item' onClick={() => navigateTo("/login")}>Sign In</div>
          </div>
       }
      </div>
      <div className={`footer-menu-container ${showMenu ? 'open' : ''}`}>
        {/* Menu Controls */}
        <a
          className={`menu-button ${showMenu ? 'open' : ''}`}
          id="open-menu"
          onClick={toggleFooterMenu}
          href="#menu"
          title={showMenu ? 'Hide navigation' : "Show navigation"}
        >
          <div className={`menu-icon-container ${showMenu ? 'open' : ''}`}>
            <i className={`fas fa-fingerprint`}/>
            <i className={`fas ${showMenu ? 'fa-minus' : 'fa-plus'}`}/>
          </div>
        </a>
      </div>
    </div>
  );
}

export default FooterMenu;