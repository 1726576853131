// IMPORTANT: Always add any new quuestion types that you build here to the constant VALID_QUESTION_TYPES in src/Constants/constants.ts ot TS will chuck a wobbly.

import React, { ReactNode, useEffect, useState } from "react";
import { FormQuestionLink, QuestionData } from "../../Types/FeedbackTypes";
import { TextBox, DatePickerComponent, MobileSignature, FileUpload, LeadIn, SpacerDiv, DateInputMask, Tooltip } from "Components";
import { useUserField, useForm } from "CustomHooks";
import { VALID_QUESTION_TYPES } from "Constants/constants";

import "../../Pages/Styles/feedback.scss";

interface QuestionProps extends QuestionData {
  // eslint-disable-next-line no-unused-vars
  handleResponseChange: (questionId: number, value: string) => void;
  showLabels: boolean;
  isFirstInGroup: boolean;
  value?: string;
  compulsoryFlag?: 0 | 1;
}

const Question: React.FC<QuestionProps> = ({
  questionId,
  questionText,
  questionType,
  options,
  links,
  handleResponseChange,
  showLabels,
  isFirstInGroup,
  value,
  compulsoryFlag,
  placeholderText,
  tooltipText,
}) => {
  const [dateValue, setDateValue] = useState<Date | null>(null);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [skipQuestion, setSkipQuestion] = useState<boolean>(false);
  const userLevel = (useUserField("accessLevel") ?? 0);
  const name = useUserField("fullName");
  const trainerName = "Trainer Name"; // Replace with real state when available
  const employerName = "Employer Name"; // Replace with real state when available
  const { isQuestionAnswered } = useForm();
  const isCompulsory = compulsoryFlag !== 0;

  // console.log('questionId', questionId, 'isCompulsory', isCompulsory, 'isQuestionAnswered', isQuestionAnswered(questionId.toString()));
  const isRequired = isCompulsory && !isQuestionAnswered(questionId.toString());
  const inputPlaceholder = placeholderText || "";
  const inputTooltip = tooltipText || "";

  // Initialize local state only once
  const [localValue, setLocalValue] = useState<string>(() => {
    if (value) return value;
    switch (questionType) {
      case 115:
        return name || "";
      case 116:
        return trainerName || "";
      case 117:
        return employerName || "";
      case 118:
        return employerName || "";
      default:
        return "";
    }
  });

  // console.log('questionType', questionType, 'questionId', questionId, 'value', value, 'localValue', localValue);

  // Controls for skipping questions based on previous answers
  useEffect(() => {
    if (questionType === 699) {
      setSkipQuestion(false); // Stop skipping at 699
      return;
    }

    if (skipQuestion) {
      return; // Keep skipping until 699 appears
    }

    if (questionType === 600 && value) {
      setSkipQuestion(true); // Start skipping
    }
  }, [questionType, value]);


  useEffect(() => {
    if (!value) {
      // Set the initial value once based on question type
      let initialValue = "";
      if (questionType === 115) {
        initialValue = name || ""; // Student/Candidate Name
      } else if (questionType === 116) {
        initialValue = trainerName || ""; // Trainer Name
      } else if (questionType === 117) {
        initialValue = employerName || ""; // Employer Name
      } else if (questionType === 303) {
        // Handle date question types
        initialValue = new Date().toISOString().slice(0, 10);
      }
  
      // Only set the initial value if it hasn't been set yet
      setLocalValue(initialValue);
  
      // Sync with global state
      if (initialValue && initialValue !== '') {
        handleResponseChange(questionId, initialValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  // Handle changes locally and sync to global state
  const handleChange = (newValue: string) => {
    setLocalValue(newValue); // Update local state
    handleResponseChange(questionId, newValue); // Sync with global state
  };

  const verticalQuestions = [401, 410, 500, 501, 502, 503, 504, 510];
  const noQuestionText = [151, 400, 402, 502, 600, 699];

  const renderQuestionText = (
    questionText: string,
    links: FormQuestionLink[] | null,
    state: { studentName?: string; candidateName?: string; employerName?: string; trainerName?: string },
  ): ReactNode => {

    if (!links || links.length === 0) {
      return <span>{questionText}</span>;
    }
  
    const parts: ReactNode[] = [];
    let currentText = questionText;
    const normalizedText = questionText.toLowerCase(); 
  
    links.forEach((link) => {
      if (!questionText) {
        return <span>No question text provided</span>;
      }
      const linkText = typeof link.linkText === "string" 
        ? link.linkText.trim().toLowerCase() 
        : '';

      const matchIndex = normalizedText.indexOf(linkText);
  
      if (matchIndex !== -1) {
        if (matchIndex > 0) {
          parts.push(
            <span key={`${linkText}-before`}>
              {currentText.slice(0, matchIndex)}
            </span>
          );
        }
        parts.push(
          <span key={`${linkText}-match`}>
            {createLink(link.linkURL || "", linkText, link.linkType || 0, state)}
          </span>
        );
        currentText = currentText.slice(matchIndex + linkText.length);
      } else {
        // console.warn(`No match found for '${linkText}'.`);
      }
    });
  
    if (currentText) {
      parts.push(
        <span key="remaining-text">
          {currentText}
        </span>
      );
    }

    return <>{parts}</>;
  };

  const getDateInputType = (placeholderText: string): string => {
    switch (placeholderText.toLowerCase()) {
      case "dob":
      case "date of birth":
        return "dob";
      case "today":
        return "today";
      default:
        return "today";
    };
  };

  const determineDateInputType = (links: { linkText: string }[] = []): "dob" | "today" => {
    for (const link of links) {
      const inputType = getDateInputType(link.linkText);
      if (inputType === "dob") return "dob"; // If we find "dob", return immediately
    }
    return "today"; // Default to "today" if no match found
  };

    
  const defaultDOB = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 16); // Subtract 16 years
  
    // Extract day, month, and year correctly
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = String(today.getFullYear());
  
    return `${day}/${month}/${year}`; // Return formatted DD/MM/YYYY
  };

  const getPlaceholderValue = (
    placeholderText: string, 
    state: {studentName?: string, candidateName?: string, employerName?: string, trainerName?: string})
    : string => {
    switch (placeholderText.toLowerCase()) {
      case "student name":
      case "candidate name":
        return state.studentName || "";
      case "employer name":
        return state.employerName || "";
      case "trainer name":
        return state.trainerName || "";
      case "Date Of Birth":
        return "dob";
      case "today":
        return "today";
      default:
        return placeholderText;
    };
  };
  
  const createLink = (
    url: string, 
    linkText: string, 
    linkType: number,
    state: {studentName?: string, candidateName?: string, employerName?: string, trainerName?: string}
  ): ReactNode => {
    let link: ReactNode = <></>;

    const placeholderValue = getPlaceholderValue(linkText, state);

    switch (linkType) {
      // Hyperlink
      case 1:
        link = (
          <a 
            href={url} 
            target="_blank" 
            rel="noopener noreferrer" 
            className='link'
            style={{ textDecoration: "underline" }}
          >
              {linkText}
          </a>
        );
        break;
      // Document
      case 2:
        link = (
          <span 
            className='document-link' 
            style={{ textDecoration: url ? "underline" : 'none', textDecorationColor: '#955cce', cursor: url ? "pointer" : "default" }}
            onClick={() => {url && typeof url === "string" ?
                window.open(url, '_blank', 'noopener, norefferer')
                  :
                console.warn('No URL provided for document link: ', linkText);
              }}>
              {linkText}
          </span>
        );
        break;
      // Document with dynamic URL -- TO DO: Implement this correctly
      case 3:
        link = (
          <span 
            className='document-link' 
            style={{ textDecoration: url ? "underline" : 'none', textDecorationColor: '#955cce', cursor: url ? "pointer" : "default" }}
            onClick={() => {url && typeof url === "string" ?
                window.open(url, '_blank', 'noopener, norefferer')
                  :
                console.warn('No URL provided for document link: ', linkText);
              }}>
              {linkText}
          </span>
        );
        break;
      // Placeholder 
      case 10: 
        link = (
          <span className="placeholder-input">{placeholderValue}</span>
        );
        break;
      default:
        link = (
          <span 
            className='unsupported-link'
            style={{ color: 'red', fontStyle: 'italic' }}
          >
            Unsupported Link Type: {linkText}
          </span>
        );
        break;  
    }
    return link;
  };


  const getMaxLength = (type: number) => {
    switch (type) {
      case 100: // Text (100 characters)
        return 100;
      case 101: // Text (250 characters)
        return 250;
      case 102: // Text (500 characters)
        return 500;
      case 181: // Number (Postcode)
        return 4;
      default:
        return 500;
    }
  };

  // Determine scale options based on questionType if applicable
  const scaleOptions =
    questionType >= 200 && questionType < 300 && questionType !== 201 && options && options.length > 0
    ? options
    : null;


  const handleCheckboxChange = (value: string) => {
    if (selectedValue === value) {
      setSelectedValue(null);
      handleResponseChange(questionId, "");
    } else {
      setSelectedValue(value);
      handleResponseChange(questionId, value);
    }
  };

  const getTodayFormatted = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = String(today.getFullYear());
    return `${day}/${month}/${year}`; // DD/MM/YYYY
  };

  // TO DO: Add support for multiple choice questions
  // TO DO: Use question targets to determine who sees a question

  // TO DO: Add support for conditional questions 600, 601, 699
  if (skipQuestion) return null;

  return (
    <div className={`question ${verticalQuestions.includes(questionType) ? '' : 'horizontal-layout'}`}>
      <div
        className="question-text"
        style={
          isFirstInGroup
            ? {
                alignSelf: "flex-end",
                paddingTop: window.innerWidth > 1000 ? "30px" : "70px",
                marginBottom: window.innerWidth > 1000 ? "50px" : "30px",
              }
            : undefined
        }
      >
      {!(noQuestionText.includes(questionType) || !VALID_QUESTION_TYPES.includes(questionType)) ? 
        (questionType === 401 && !name ? (
            <div className="warning-text">
              The candidate / student name is not available for this consent form. Please contact your trainer for assistance.
            </div>
          ) : (
            <div className="question-text-wrapper">
            {/* Question Text */}
           {inputTooltip ?
            <Tooltip content={inputTooltip} position="top">
              <p className="question-text-content">
                {renderQuestionText(
                  questionText, 
                  links || null, 
                  {
                    studentName: name, 
                    candidateName: name,
                    employerName: 'Employer Name',
                    trainerName: 'Trainer Name'
                  }
                )}
              </p>
            </Tooltip>
            : 
            <p className="question-text-content">
            {renderQuestionText(
              questionText, 
              links || null, 
              {
                studentName: name, 
                candidateName: name,
                employerName: 'Employer Name',
                trainerName: 'Trainer Name'
              }
            )}
          </p>
            }

        
          </div>
          )
        ) : null}


        {/* Text entry questions */}
        {(questionType === 100 || questionType === 101 || questionType === 102) && (
          <>
            <TextBox
              className={`${isRequired ? 'text required' : 'text'}`}
              questionLength={getMaxLength(questionType)}
              value={value || ""}
              onChange={(value) => handleResponseChange(questionId, value)}
              style={{ width: "100%" }}
              placeholder={inputPlaceholder}
            />
          </>
        )}

        {/* Phone number entry */}
        {questionType === 110 && (
          <input
            className={`form-input ${isRequired ? 'text required' : 'text'}`}
            type="tel"
            maxLength={15}
            value={value || ""}
            onInput={(e) => {
              const input = e.target as HTMLInputElement;
              input.value = input.value.replace(/[^0-9+\-\s()]/g, "");
              handleResponseChange(questionId, input.value);
            }}
            style={{ width: "100%" }}
          />
        )}

        {/* Mobile number entry */}
        {questionType === 112 && (
          <input
            className={`form-input ${isRequired ? 'text required' : 'text'}`}
            type="email"
            value={value || ""}
            onInput={(e) => {
              const input = e.target as HTMLInputElement;
              handleResponseChange(questionId, input.value);
            }}
            style={{ width: "100%" }}
          />
        )}

         {/* Address */}
         {(questionType === 150 || questionType === 151) && (
          <div className="address-input">
            {/* Address Line 1 */}
            {questionType === 150 && (
              <input
                className={`form-input ${isRequired ? 'text required' : 'text'}`}
                type="text"
                value={value || ""}
                placeholder="Unit / Street Number"
                onChange={(e) =>
                  handleResponseChange(questionId, e.target.value)
                }
                style={{ width: "100%" }}
              />
            )}
            {/* Address Line 2 */}
            {questionType === 151 && (
              <input
                className={`form-input ${isRequired ? 'text required' : 'text'}`}
                type="text"
                value={value || ""}
                placeholder="Street Name, Suburb, State, Postcode"
                onChange={(e) =>
                  handleResponseChange(questionId, e.target.value)
                }
                style={{ width: "100%" }}
              />
            )}
          </div>
        )}

        {/* Name entry */}
        {(questionType === 115 || questionType === 116 || questionType === 117) && (
            <input
            className={`form-input ${isRequired ? 'text required' : 'text'}`}
            type="text"
            value={localValue}
            placeholder={
              questionType === 115
                ? "Enter student or candidate name"
                : questionType === 116
                ? "Enter trainer name"
                : questionType === 117
                ? "Enter employer name"
                : ""
            }
            onChange={(e) => handleChange(e.target.value)}
            style={{ width: "100%", marginTop: "10px" }}
          />
        )}


        {/* Cash entry */}
        {questionType === 120 && (
          <input
            className={`form-input ${isRequired ? 'text required' : 'text'}`}
            type="text"
            value={`$ ${value || ""}`}
            onChange={(e) => {
              const input = e.target.value.replace(/[^0-9.]/g, "");
              let numericValue = input.replace(/(\..*?)\..*/g, "$1");
              if (numericValue.includes(".")) {
                const [whole, decimal] = numericValue.split(".");
                numericValue = `${whole}.${decimal.slice(0, 2)}`;
              }
              handleResponseChange(questionId, numericValue);
            }}
            onBlur={(e) => {
              const input = e.target.value.replace(/[^0-9.]/g, "");
              if (!input) {
                handleResponseChange(questionId, "");
              }
            }}
            style={{ width: "100%", marginTop: "10px" }}
          />
        )}


        {/* Single choice questions */}
        {questionType === 201 && options && (
          <select
          className={`form-input text ${isCompulsory && !isQuestionAnswered(questionId.toString()) ? 'required' : ''}`}
          onChange={(e) => handleResponseChange(questionId, e.target.value)}
          value={value || ""}
          style={{ width: "100%", marginTop: "10px" }}
        >
        
            <option value="" disabled className="placeholder">
              Select an option
            </option>
            {options.map((option) => (
              <option key={option.optionId} value={option.optionId.toString()}>
                {option.optionText}
              </option>
            ))}
          </select>
        )}

        {/* Date Entry */}
        {/* {questionType >= 301 && questionType <= 303 && (
          <DatePickerComponent
            selectedDate={dateValue || (value ? new Date(value) : null)}
            onDateChange={(date) => {
              setDateValue(date);
              handleResponseChange(
                questionId,
                date ? date.toISOString().slice(0, 7) : ""
              );
            }}
            hideDays={questionType !== 303 } // Hide days for `YYYY`
            hideMonths={questionType === 301} // Hide months for `YYYY`
            placeholderText={
              questionType === 301
                ? "YYYY"
                : questionType === 302
                ? "MM/YYYY"
                : "DD/MM/YYYY"
            }
          />
        )} */}

        {/* {questionType >= 301 && questionType <= 303 && (() => {
          const inputType = determineDateInputType(links || []); // Extract inputType from links
          const isRequired = inputType === "dob" && (!value || value === defaultDOB());

          return (
            <DateInputMask
              inputType={inputType}
              className={`form-input ${isRequired ? 'text required' : 'text'}`}
              value={value || ""}
              onChange={(value) => handleResponseChange(questionId, value)}
              questionType={questionType}
            />
          );
        })()} */}

        {questionType >= 301 && questionType <= 303 && (() => {
          const inputType = determineDateInputType(links || []);
          const isRequired = inputType === "dob" && !value; // Only required if empty

          return (
            <DateInputMask
              inputType={inputType}
              className={`form-input ${isRequired ? 'text' : 'text required'}`}
              value={value || ""}
              onChange={(newValue) => {
                handleResponseChange(questionId, newValue);
              }}
              questionType={questionType}
            />
          );
        })()}



        {questionType === 180 && (
          <input
            className={`form-input ${value && value !== "" ? 'text required' : 'text'}`}
            type="number"
            value={value || ""}
            onInput={(e) => {
              const input = e.target as HTMLInputElement;
              handleResponseChange(questionId, input.value);
            }}
            style={{ width: "80px"}}
          />  
          )}

        {/* Number Entry */}
        {questionType === 181 && (
          <input
            className={`form-input ${value && value !== "" ? 'text required' : 'text'}`}
            type="number"
            value={value || ""}
            maxLength={getMaxLength(questionType)}
            onInput={(e) => {
              const input = e.target as HTMLInputElement;
              if (input.value.length > getMaxLength(questionType)) {
                input.value = input.value.slice(0, getMaxLength(questionType));
              }
              handleResponseChange(questionId, input.value);
            }}
            style={{ width: "80px" }}
          />
        )}
      </div>

       {/* Scale questions */}
       {scaleOptions && (
         <div className="question-options">
         {questionType === 203 || questionType === 251 ? (
           <div className="scale-radio-options-horizontal">
             {scaleOptions.map((option) => {
               return (
                 <label
                   htmlFor={`option-${questionId}-${option.optionId}`}
                   key={option.optionId}
                   className="scale-option-horizontal"
                 >
                   <input
                     className={`radio-as-checkbox ${isQuestionAnswered(questionId.toString()) ? '' : 'required'}`}
                     type="checkbox"
                     id={`option-${questionId}-${option.optionId}`}
                     name={`question-${questionId}`}
                     value={option.optionId}
                     checked={String(value) === option.optionId.toString()}
                    //  checked={false}
                     onChange={() => handleCheckboxChange(option.optionId.toString())}
                   />
                   <span>{option.optionText}</span>
                 </label>
               );
             })}
           </div>
          ) : (
            <>
              {/* Only show labels if `showLabels` is true (first in the group) */}
              {showLabels && (
                <div className="scale-labels">
                  {scaleOptions.map((option) => (
                    <span
                      key={`label-${option.optionId}`}
                      className="scale-label"
                    >
                      {option.optionText}
                    </span>
                  ))}
                </div>
              )}
              <div className="scale-radio-options">
                {scaleOptions.map((option) => (
                  <label
                    htmlFor={`option-${questionId}-${option.optionId}`}
                    key={option.optionId}
                    className="scale-option"
                  >
                    <input
                      className="radio-as-checkbox"
                      type="checkbox"
                      id={`option-${questionId}-${option.optionId}`}
                      name={`question-${questionId}`}
                      value={option.optionId.toString()}
                      checked={value === option.optionId.toString()}
                      onChange={() => handleCheckboxChange(option.optionId.toString())}
                    />
                    <span></span> {/* Styling for checkbox appearance */}
                  </label>
                ))}
              </div>
            </>
          )}
        </div>
      )}

      {/* Lead in / instructions */}
      {questionType === 400 && (
        <div>
          <LeadIn title='' description={questionText}/>
        </div>
      )}

      {/* Consent + T's and C's */}
      {questionType === 401 && name && (
        <div>
          <ul className='consent-list'>
            {options?.map((option) => (
              <li className='consent-list-item' key={option.optionId}>{option.optionText}</li>
            ))}
          </ul>
          <label>
            <input
              className={`radio-as-checkbox ${isQuestionAnswered(questionId.toString()) ? '' : 'required'}`}
              type="checkbox"
              checked={value === "1"}
              onChange={(e) =>
                handleResponseChange(questionId, e.target.checked ? "1" : "")
              }
            />
            <span>Yes, I confirm</span>
          </label>
        </div>
      )}

      {/* Disclaimer / Fine print */}
      {questionType === 402 && (
        <div className='disclaimer'>
          {questionText}
        </div>
      )}

      {/* Signature */}
      {questionType === 410 && (
          <MobileSignature
            onSave={(signature) => handleResponseChange(questionId, signature)}
          />
      )}

      {/* Any file type upload */}
      {questionType === 500 && (
          <div>
            questionType {questionType}
            <FileUpload onChange={() => console.log("File uploaded for:", questionType)}/>
          </div>
      )}

      {/* Image upload */}
      {questionType === 501 && (
          <div>
            questionType {questionType}
            <div>Thumbnail goes here! These should flex row and then wrap.</div>
            <FileUpload onChange={() => console.log("File uploaded for:", questionType)}/>
          </div>
      )}

      {/*  PDF upload */}
      {questionType === 502 && (
          <div>
            <SpacerDiv rem={0.5} />
            {questionText}
            {/* <div>Thumbnail for the first 2 pages goes here! With an a tag that opens a target blank to show the whole file.</div> */}
            <FileUpload acceptedFileType='application/pdf' onChange={() => console.log("File uploaded for:", questionType)}/>
          </div>
      )}

      {/* Video upload */}
      {questionType === 503 && (
          <div>
            questionType {questionType}
            <div>Thumbnail goes here! Should be a small media player so they can see that it is uploaded correctly</div>
            <FileUpload onChange={() => console.log("File uploaded for:", questionType)}/>
          </div>
      )}

      {/* Audio upload */}
      {questionType === 504 && (
          <div>
            questionType {questionType}
            <div>Thumbnail goes here! Needs to be clickable for audio playback</div>
            <FileUpload onChange={() => console.log("File uploaded for:", questionType)}/>
          </div>
      )}

      {/* Dynamic upload link with no answer render */}
      {questionType === 510 && (
          <div>
            questionType {questionType}
            <FileUpload onChange={() => console.log("File uploaded for:", questionType)}/>
          </div>
      )}

      {/* Dynamic upload link with no answer render */}
      {questionType === 510 && (
          <div>
            questionType {questionType}
            <FileUpload onChange={() => console.log("File uploaded for:", questionType)}/>
          </div>
      )}

      {/* If no/0/false, show the following questions until a Qtype 699 is found */}
      {questionType === 600 && (
        <div>
          <span>Question will be skipped if the previous answer is Truthy</span>
        </div>  
      )}

      {/* End of conditional questions - questions after this will be shown regardless of the previous answer */}
      {questionType === 699 && (
        <div>
          <span>End of conditional questions - questions after this will be shown regardless of the previous answer</span>
        </div>  
      )}



      {/* Fallback for unsupported question types in the incoming data*/}
      {!VALID_QUESTION_TYPES.includes(questionType) && (
          <div style={{ color: 'red', fontWeight: 'bold' }}>
            questionType: {questionType} is not currently supported by this form.
          </div>
      )}
    </div>
  );
};

export default Question;
