export const fetchStudentHistory = async (roleId: any, fetchApi: any) => {
  try {
    const response = await fetchApi("student/student_history", { roleId });

    if (response.status >= 200 && response.status < 300) {
      console.log(response.responseData.student_history);
      return {
        success: true,
        data: response.responseData.student_history,
        msgCode: 1,
      };
    } else {
      return {
        success: false,
        msg: response.msg,
        msgCode: 2,
      };
    }
  } catch (error) {
    console.error("Fetch failed:", error);
    return {
      success: false,
      msg: "An unexpected error occurred. Contact your administrator.",
      msgCode: 4,
    };
  }
};
