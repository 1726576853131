import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../Styles/datePicker.scss";

interface DatePickerComponentProps {
  selectedDate: Date | null;
  onDateChange: (date: Date | null) => void;
  onBlur?: () => void;
  placeholderText?: string;
  containerStyle?: string;
  popperClassName?: string;
  hideDays?: boolean; // For `302`: MM/YYYY
  hideMonths?: boolean; // For `301`: YYYY
  className?: string;
  required?: boolean;
}

const DatePickerComponent: React.FC<DatePickerComponentProps> = ({
  selectedDate,
  onDateChange,
  onBlur,
  placeholderText = "Select Date",
  containerStyle = "custom-datepicker-wrapper",
  popperClassName = "custom-datepicker-popper",
  hideDays = false,
  hideMonths = false,
  className,
  required = false,
}) => {
  const [currentDate, setCurrentDate] = useState<Date | null>(
    selectedDate || new Date()
  );
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setCurrentDate(selectedDate);
  }, [selectedDate]);

  const handleDateChange = (date: Date | null) => {
    setCurrentDate(date);
    onDateChange(date);
    setIsOpen(false);
  };

  const getDateFormat = () => {
    if (hideDays) return hideMonths ? "yyyy" : "MM/yyyy"; // `301`: YYYY or `302`: MM/YYYY
    return "dd/MM/yyyy"; // `303`: DD/MM/YYYY
  };

  const handleCalendarClose = () => {
    setIsOpen(false);
    if (onBlur) onBlur();
  };

  return (
    <div className={`custom-datepicker ${className}`}>
      {isOpen && (
        <div className="overlay" onClick={() => setIsOpen(false)}></div>
      )}
      <DatePicker
        selected={currentDate}
        onChange={handleDateChange}
        onCalendarOpen={() => setIsOpen(true)}
        onCalendarClose={handleCalendarClose}
        dateFormat={getDateFormat()}
        showMonthYearPicker={hideDays && !hideMonths} // Show month/year picker for `302`
        showYearPicker={hideDays && hideMonths} // Show year picker for `301`
        placeholderText={placeholderText}
        customInput={
          <input
            type="text"
            className={required ? "required" : ""}
            value={
              currentDate
                ? hideDays
                  ? hideMonths
                    ? `${currentDate.getFullYear()}` // `301`
                    : `${currentDate.getMonth() + 1}/${currentDate.getFullYear()}` // `302`
                  : `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}` // `303`
                : ""
            }
            onChange={() => {}}
            onBlur={onBlur}
            readOnly
          />
        }
        wrapperClassName={containerStyle}
        popperClassName={popperClassName}
        renderCustomHeader={({ date, decreaseYear, increaseYear, increaseMonth, decreaseMonth }) => (
          <div>
            {/* MMyyyy */}
            <div className="year">
              <button className="year-button left" onClick={decreaseYear}>
                &#9664;
              </button>
              <span className="year-display">{date.getFullYear()}</span>
              <button className="year-button right" onClick={increaseYear}>
                &#9654;
              </button>
            </div>
            {/* ddMMyyyy */}
            {!hideDays && !hideMonths && (
              <div className="month">
                <button className="month-button left" onClick={decreaseMonth}>
                  &#8722;
                </button>
                <span className="month-display">
                  {new Intl.DateTimeFormat("en-US", { month: "short" }).format(date)}
                </span>
                <button className="month-button right" onClick={increaseMonth}>
                  &#43;
                </button>
              </div>
            )}
          </div>
        )}
      />
      <style>{`
        .custom-datepicker-popper {
          z-index: 1000;
        }
      `}</style>
    </div>
  );
  
};

export default DatePickerComponent;
