import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";

interface DateInputMaskProps {
  value?: string;
  onChange: (value: string) => void;
  questionType: number;
  className?: string;
  inputType?: 'dob' | 'today';  
  isDOBInput?: boolean;
  isTodayInput?: boolean;
}

const DateInputMask: React.FC<DateInputMaskProps> = ({
  value = "",
  onChange,
  questionType,
  className,
  inputType,
}) => {
  const getMask = () => {
    if (questionType === 301) return "9999"; // YYYY
    if (questionType === 302) return "99/9999"; // MM/YYYY
    return "99/99/9999"; // DD/MM/YYYY (Default)
  };

  
  const getFormattedDate = () => {
    const today = new Date();

    if (questionType === 301) return today.getFullYear().toString(); // YYYY

    if (questionType === 302) {
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      return `${month}/${today.getFullYear()}`; // MM/YYYY
    }

    // Default: DD/MM/YYYY
    const day = today.getDate().toString().padStart(2, "0");
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    return `${day}/${month}/${today.getFullYear()}`; // DD/MM/YYYY
  };
  
  const defaultDOB = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 16); // Subtract 16 years
  
    // Extract day, month, and year correctly
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = String(today.getFullYear());
  
    return `${day}/${month}/${year}`; // Return formatted DD/MM/YYYY
  };

  const today = (date: string) => {
    return date === getFormattedDate();
  };

  // Normalize the initial date value depending on the input type
  const [dateValue, setDateValue] = useState<string>(() => {
    if (inputType === "today") return getFormattedDate(); // Set to today's date
    if (inputType === "dob") return ""; // Empty for DOB, uses placeholder
    return value || ""; // Use passed value if available
  });
  
  useEffect(() => {
    if (!value && inputType === "today") {
      const formattedDate = getFormattedDate();
      setDateValue(formattedDate);
      onChange(formattedDate);
    }
  }, [value, inputType]);


  return (
    <InputMask
      mask={getMask()}
      value={dateValue}
      onChange={(e) => {
        setDateValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={inputType === "dob" ? defaultDOB() : ''}
      className={className}
    />
  );
};

export default DateInputMask;
