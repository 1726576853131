import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Bluey from "Components/Bluey";
import { useLocation } from "react-router-dom";
import List, { ItemDragging } from "devextreme-react/list";
import {
  Alert as CustomAlert,
  SignaturePanelPC as SignaturePanel,
  MobileSignaturePanel,
  MobilePDFHandler,
} from "Components";
import { annotations } from "TestingDataObjects/annotations";
import useFetchApi from "Services/FetchApi";
import { useUserDeets, useViewport, useSignature, useAssessmentAnswers } from "CustomHooks";
import { useDispatch } from "react-redux";
import { saveAnswers } from "State/Reducers/assessmentSlice";
import { useSelector } from "react-redux";
import { useDropzone } from 'react-dropzone';
import { FiUpload, FiSend } from 'react-icons/fi';


export default function Assessment() {
  const init = useRef(false);
  const [showCoverSheet, setShowCoverSheet] = useState(true);
  const signaturePanelRef = useRef(null);
  const mobileSignaturePanelRef = useRef(null);
  const fetchApi = useFetchApi();
  const user = useUserDeets();
  const location = useLocation();
  const navigate = useNavigate();
  const environment = process.env.REACT_APP_ENV;
  const data = location.state ? location.state.jsonObject : null;
  const [autoMarking, setAutoMarking] = useState(true);
  const [modId] = useState(data.modID);
  const [apprenticeModsId] = useState(data.apprenticeModsId);
  const [certId] = useState(data.certID);
  const [modCode] = useState(data.modCode);
  const [modName] = useState(data.modName);
  const [studentId] = useState(user.roleId);
  const [classId] = useState(data.classId);

  const [msg, setMsg] = useState(window.sessionStorage.getItem("message"));
  const [msgCode, setMsgCode] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isValid, setIsValid] = useState(true);
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [mode, setMode] = useState("assessment");
  const [status, setStatus] = useState("Not Yet Started");
  const [completed, setCompleted] = useState(false);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [actualAssessmentData, setActualAssessmentData] = useState({});
  const [practicalData, setPracticalData] = useState({})
  // const [supplementaryData, setSupplementaryData] = useState([]);
  // const [supplementaryQuestions, setSupplementaryQuestions] = useState([]);
  const [currentData, setCurrentData] = useState({});

  // const answers = useSelector((state) => state.assessment.answers);
  // const [localAssessmentState, setLocalAssessmentState] = useState({
  //   answers: {},
  // });


  const { clearSignature } = useSignature();
  const { showMobile } = useViewport();
  // console.log('Show Mobile:', showMobile);
  const [showMobileSignatureCanvas, setShowMobileSignatureCanvas] = useState(false);
  const [showMobileSignature, setShowMobileSignature] = useState(false); 
  const handleOpenMobileSignaturePanel = () => {
      setShowMobileSignatureCanvas(true);
      setShowMobileSignature(true);
  };

  // Initialize the date signed state with the current date
  // This will be updated when the document is signed via the MobileSignaturePanel and passed to the MobilePDFHandler
  const [dateSigned, setDateSigned] = useState(new Date());

  const [playAnimation, setPlayAnimation] = useState(false);

  useEffect(() => {
    if (playAnimation) {
      setTimeout(() => {
        setPlayAnimation(false);
      }, 2000);
    }
  }, [playAnimation]);

  useEffect(() => {
    setPlayAnimation(true);
  }, []);

  // useEffect(() => {
    // console.log('Class ID in Assessment.js: ', classId)
  // }, [classId]);

  const instructionsText =
    "IMPORTANT: Training Professionals is collecting information on this and other forms to satisfy government reporting requirements. This information may be reported to AVETMISS as required by the State Training Authority and their associated reporting agencies. If your compentencies result in an external Licence or Passport, enough information will be passed to the relevant issuing authority to complete that process. By signing this form you give us permission to request your USI and share the relevant information with the appropriate government agency. Your personal information will not be disclosed to any other third party without your consent unless authorised or required by law and only authorised departmental officers will have access to the information you provide. Your signature is your agreement that you were provided with information on how you were to be assessed and that this assessment is your own work completed in accordance with the competency criteria.";

  const { clearAnswers } = useAssessmentAnswers();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      // console.log('Nuking student answers');
      clearAnswers();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const print = (variable) => {
    console.log(variable);
  };

  const log = (vars) => {
    Object.entries(vars).forEach(([key, value]) =>
      console.log(`${key}:`, value)
    );
  };

  useEffect(() => {
    setMsgCode(0); // Reset the message code (if it was set to 1, 2, or 3 previously
    setMsg("");

    // log({ data });

    if (!init.current && data.modID) {
      // Only fetch data if the component is mounted
      fetchData(apprenticeModsId, user.roleId, modId);
      fetchStatus(apprenticeModsId, user.roleId, modId);
      init.current = true; // Ensure fetch is only called once
    }

    // Prevent the user from accidentally leaving the page with unsaved changes
    const handleBeforeUnload = (event) => {
      const message =
        "Are you sure you want to leave? All unsaved changes will be lost.";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    window.onbeforeunload = function () {
      return "Are you sure you want to navigate away?";
    };

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // const handleRadioChange = (index) => {
  //   setSelectedRadio(index);
  //   // Wipe out previous answer if it exists
  //   setLocalAssessmentState((prev) => {
  //     const updated = { ...prev };
  //
  //     // Clean slate for this question
  //     if (updated.answers?.[data.questionNum]) {
  //       updated.answers[data.questionNum] = {};
  //     }
  //
  //     // Now set the new answer
  //     updated.answers = {
  //       ...updated.answers,
  //       [data.questionNum]: {
  //         [`a${index + 1}`]: true,
  //       },
  //     };
  //
  //     return updated;
  //   });
  // };


  const handleData = (apiData) => {

    if (!apiData) return;

    console.log("Data from Api:", apiData);

    setAutoMarking(apiData.autoMarking || 1);

    // === Perform status check here === //
    if (status === "completed") {
      setCompleted(true);
      setMode("completed");
    } else if (status === "signed") {
      setShowCoverSheet(false);
    }

    const knowledgeData = apiData.assessmentData;
    const practicalData = apiData.practicalData;
    const supplementaryQuestions = apiData.supplementaryQuestions;
    const supplementaryData = apiData.supplementaryData;
    const questionPool = apiData.questionPool;

    // === Calculate the number of real questions === //
    let totalQuestions = {};

    Object.keys(knowledgeData).forEach(() => {
      const realQuestions = knowledgeData.filter(
          (item) => ![-1, 13, 14, 4].includes(item.QType)
      );
      totalQuestions = realQuestions.length; // Store the number of real questions for each taskKey
    });

    // === Inject Supplementary Questions AFTER NS Questions Instead of Replacing === //
    let filteredAssessmentData = [];
    let supplementaryQueue = [];

    knowledgeData.forEach((item) => {
      if (item.outcome === "NS") {
        const updatedNSQuestion = { ...item, isSupplementary: true };
        filteredAssessmentData.push(updatedNSQuestion);

        const supplementary = supplementaryQuestions.find(
            (sup) => sup.questionNum === item.questionNum && sup.mapping === item.mapping
        );

        if (supplementary) {
          supplementaryQueue.push({ ...supplementary });
        }
      } else {
        filteredAssessmentData.push(item);
      }
    });

    supplementaryData.forEach((item) => {
      if (item.outcome === "NS") {
        const updatedNSQuestion = { ...item, isSupplementary: true };
        filteredAssessmentData.push(updatedNSQuestion);

        const supplementary = questionPool.find(
            (sup) => sup.questionNum === item.questionNum && sup.mapping === item.mapping
        );

        if (supplementary) {
          supplementaryQueue.push({ ...supplementary });
        }
      } else {
        filteredAssessmentData.push(item);
      }
    });

    // 🔚 Append introductoryfilteredAssessmentData.push({ ...supplementaryQuestions[1] }); supplementary questions first
    // if (supplementaryQuestions[0]) {
    //   filteredAssessmentData.push({ ...supplementaryQuestions[0] });
    // }
    // if (supplementaryQuestions[1]) {
    //   filteredAssessmentData.push({ ...supplementaryQuestions[1] });
    // }

    // log({supplementaryQueue})

    if (supplementaryQueue.length > 0) {
      filteredAssessmentData.push({ ...supplementaryQuestions?.[0] });
      filteredAssessmentData.push({ ...supplementaryQuestions?.[1] });
      filteredAssessmentData.push(...supplementaryQueue);
    }

    // 🔚 Append supplementary questions at the end



    // knowledgeData.forEach((item) => {
    //
    //   if (item.outcome === "NS") {
    //
    //     // print('handeling supp question...')
    //     const updatedNSQuestion = { ...item, isSupplementary: true };
    //     filteredAssessmentData.push(updatedNSQuestion);
    //
    //     // Find the matching supplementary question
    //     const supplementary = supplementaryQuestions.find(
    //         (sup) => sup.questionNum === item.questionNum && sup.mapping === item.mapping
    //     );
    //
    //     if (supplementary) {
    //       // Inject the supplementary question directly after the NS question
    //       filteredAssessmentData.push({
    //         ...supplementary
    //          // Add extra stuff here
    //       });
    //     }
    //   } else {
    //     filteredAssessmentData.push(item);
    //   }
    //
    // });
    //
    // supplementaryData.forEach((item) => {
    //
    //   if (item.outcome === "NS") {
    //
    //     // print('handeling supp question...')
    //     const updatedNSQuestion = { ...item, isSupplementary: true };
    //     filteredAssessmentData.push(updatedNSQuestion);
    //
    //     // Find the matching supplementary question
    //     const supplementary = questionPool.find(
    //         (sup) => sup.questionNum === item.questionNum && sup.mapping === item.mapping
    //     );
    //
    //     if (supplementary) {
    //       // Inject the supplementary question directly after the NS question
    //       filteredAssessmentData.push({
    //         ...supplementary
    //         // Add extra stuff here
    //       });
    //     }
    //   } else {
    //     filteredAssessmentData.push(item);
    //   }
    //
    // });

    // log({filteredAssessmentData})

    // Remove unnecessary items based on their outcome
    filteredAssessmentData = filteredAssessmentData.filter(
        (item) => item.outcome !== "S" && item.outcome !== "NYM" && item.outcome !== "SUP"
    );

    // Final sanity check
    filteredAssessmentData.forEach((item) => {
      const matchingSupplementary = supplementaryQuestions.find(
          (sup) => sup.questionNum === item.questionNum && sup.mapping === item.mapping
      );

      if (matchingSupplementary) {
        // console.log(
        //     `✅ Verified: QuestionNum: ${item.questionNum}, Mapping: ${item.mapping}`
        // );
      }
    });

    // === Set the mode based on conditions === //
    const realAssessmentData = filteredAssessmentData.filter(
        (item) => ![-1, 13, 14, 4].includes(item.QType)
    );

    // log({filteredAssessmentData, realAssessmentData})

    // Check if at least one question remains in the assessment data after filtering
    if (realAssessmentData.length < 1) {
      // If there are assessment questions remaining, skip the intro and go straight to next assessment
      // question. If no assessment then move to practical and repeat... so on until completed
      if (practicalData.length > 1) {
        setMode("practical");
      } else {
        setMode("completed");
      }
    }

    setTotalQuestions(totalQuestions);

    // Set the filtered data for assessments, practical, and supplementary sections
    setActualAssessmentData(filteredAssessmentData);
    if (practicalData.length > 0) {
      setPracticalData(practicalData);
    }
    // setSupplementaryData(supplementaryQuestions);
    setCurrentData(filteredAssessmentData);
    // setLocalAssessmentState({answers: })
  };


  async function fetchStatus(apprenticeModId, studentId, modId) {
    // log({ apprenticeModID, studentID, modID });

    await fetchApi("assessment/assessmentStatus", {
      apprenticeModId,
      studentId,
      modId,
    }).then((response) => {
      switch (true) {
        case response.status >= 200 && response.status < 300:
          // console.log(response.responseData);
          setStatus("In progress");
          // log({response})
          if (response.responseData[0].isSigned_Student) {
            // setIsSigned(true);
            setShowCoverSheet(false);
          }
          break;
        default:
          break;
      }
    });
  }

  async function fetchData(apprenticeModID, studentID, modID) {
    // log({ apprenticeModID, studentID, modID });

    fetchApi("assessment/assess_questions", {
      apprenticeModID,
      studentID: user.roleId,
      modID,
      classId
    })
      .then((response) => {
        switch (true) {
          case response.status >= 200 && response.status < 300:
            handleData(response.responseData);
            setMsgCode(0);
            break;
          default:
            setMsg(response.msg);
            setMsgCode(4);
            break;
        }
      })
      .catch((error) => {
        // console.error("Fetch failed or fetch function is unreachable:", error);
        setMsg(
          "An unexpected error occurred. Contact your administrator" + error
        );
        setMsgCode(4);
      });
  }

  function formatStringSafely(str) {
    if (!str) return str;

    // Use DOMParser to convert HTML into plain text
    const parser = new DOMParser();
    const parsedDoc = parser.parseFromString(str, "text/html");
    // let formattedStr = parsedDoc.body.innerHTML; // Maintain HTML structure for further custom transformations
    let formattedStr = parsedDoc.body.textContent || ""; // Extract plain text content

    // let formattedStr = str;

    // Handle the special case for "(Fill in the blanks) - "
    const fillInTheBlanksIndex = formattedStr.indexOf(
        "(Fill in the blanks) - "
    );
    if (fillInTheBlanksIndex !== -1) {
      const beforeBlanks = formattedStr.substring(
          0,
          fillInTheBlanksIndex + "(Fill in the blanks) - ".length
      );
      const afterBlanks = formattedStr
          .substring(fillInTheBlanksIndex + "(Fill in the blanks) - ".length)
          .replace(/-/g, " "); // Replace '-' with ' '
      formattedStr = `${beforeBlanks}\n${afterBlanks}`;
    }

    // Replace "Â" with "-"
    formattedStr = formattedStr.replace(/Â/g, '');

    // Add a space after every period, if it is not already followed by a space or end of string
    formattedStr = formattedStr.replace(/(\.)(?!\s|$)/g, ". ");

    // Add a newline character after every colon
    formattedStr = formattedStr.replace(/:/g, ":\n");

    // Add a newline character after every semi-colon
    // formattedStr = formattedStr.replace(/;/g, ";\n");

    // Replace <li> and </li> with line breaks
    formattedStr = formattedStr.replace(/<\/?li>/g, "\n");

    // Replace &apos; with regular apostrophe
    formattedStr = formattedStr.replace(/&apos;/g, "'");

    // Replace ~number with a dash
    formattedStr = formattedStr.replace(/~\d+/g, "-");

    // Remove <text:s/>
    formattedStr = formattedStr.replace(/<text:s\/>/g, "");

    // Remove <br> tags
    formattedStr = formattedStr.replace(/<br>/g, "\n");

    // Replace quot; with ""
    formattedStr = formattedStr.replace(/quot;/g, '"');

    // Remove any underscores meant as placeholders (e.g., "________")
    formattedStr = formattedStr.replace(/_+/g, "");

    // Existing logic for handling #. pattern
    formattedStr = formattedStr
        .split("<br>")
        .map((paragraph) => {
          // Check if the paragraph contains #. pattern
          if (paragraph.includes("#.")) {
            const parts = paragraph.split("#.");
            const intro = parts.shift(); // text before the first #.
            const bullets = parts.map((item) => "• " + item.trim()).join("\n");
            return `${intro}\n${bullets}`;
          }
          return paragraph;
        })
        .join("\n");

    return formattedStr;
  }

  function formatString(str) {
    if (!str) return str;

    // Use DOMParser to convert HTML into plain text
    const parser = new DOMParser();
    const parsedDoc = parser.parseFromString(str, "text/html");
    // let formattedStr = parsedDoc.body.innerHTML; // Maintain HTML structure for further custom transformations
    let formattedStr = parsedDoc.body.textContent || ""; // Extract plain text content

    // let formattedStr = str;

    // Handle the special case for "(Fill in the blanks) - "
    const fillInTheBlanksIndex = formattedStr.indexOf(
      "(Fill in the blanks) - "
    );
    if (fillInTheBlanksIndex !== -1) {
      const beforeBlanks = formattedStr.substring(
        0,
        fillInTheBlanksIndex + "(Fill in the blanks) - ".length
      );
      const afterBlanks = formattedStr
        .substring(fillInTheBlanksIndex + "(Fill in the blanks) - ".length)
        .replace(/-/g, " "); // Replace '-' with ' '
      formattedStr = `${beforeBlanks}\n${afterBlanks}`;
    }

    // Replace "Â" with "-"
    formattedStr = formattedStr.replace(/Â/g, '');

    // Add a space after every period, if it is not already followed by a space or end of string
    formattedStr = formattedStr.replace(/(\.)(?!\s|$)/g, ". ");

    // Add a newline character after every colon
    formattedStr = formattedStr.replace(/:/g, ":\n");

    // Add a newline character after every semi-colon
    // formattedStr = formattedStr.replace(/;/g, ";\n");

    // Replace <li> and </li> with line breaks
    formattedStr = formattedStr.replace(/<\/?li>/g, "\n");

    // Replace &apos; with regular apostrophe
    formattedStr = formattedStr.replace(/&apos;/g, "'");

    // Replace ~number with a dash
    formattedStr = formattedStr.replace(/~\d+/g, "-");

    // Remove <text:s/>
    formattedStr = formattedStr.replace(/<text:s\/>/g, "");

    // Remove <br> tags
    formattedStr = formattedStr.replace(/<br>/g, "\n");

    // Replace quot; with ""
    formattedStr = formattedStr.replace(/quot;/g, '"');

    // Remove any underscores meant as placeholders (e.g., "________")
    formattedStr = formattedStr.replace(/_+/g, "");

    // **Bold and underline the word "not"**
    formattedStr = formattedStr.replace(/\bnot\b/gi, "<strong><u>not</u></strong>");

    // **Bold and underline the word "cannot"**
    formattedStr = formattedStr.replace(/\bcannot\b/gi, "<strong><u>cannot</u></strong>");

    // **Bold and underline the word "all"**
    // formattedStr = formattedStr.replace(/\bSelect all that apply\b/gi, "Select <strong><u>all</u></strong> that apply");
    // formattedStr = formattedStr.replace(/\ball\b/gi, "<strong><u>all</u></strong>");
    formattedStr = formattedStr.replace(/\b(all)\b/gi, "<strong><u>$1</u></strong>");

    // Existing logic for handling #. pattern
    formattedStr = formattedStr
      .split("<br>")
      .map((paragraph) => {
        // Check if the paragraph contains #. pattern
        if (paragraph.includes("#.")) {
          const parts = paragraph.split("#.");
          const intro = parts.shift(); // text before the first #.
          const bullets = parts.map((item) => "• " + item.trim()).join("\n");
          return `${intro}\n${bullets}`;
        }
        return paragraph;
      })
      .join("\n");

    return formattedStr;
  }

  const splitQuestion = (text) => {
    const segments = [];
    let lastIndex = 0;

    const regex = /(\S*?)\[([^\]]+)\](\S*?)(?=\s|$)/g; // Matches word, gap, and following word
    let match;

    while ((match = regex.exec(text)) !== null) {
      // Add any text that precedes the match
      if (lastIndex !== match.index) {
        segments.push({
          type: "text",
          content: text.substring(lastIndex, match.index),
        });
      }
      // Combine prefix, gap, and suffix into a single segment
      segments.push({
        type: "word_with_gap",
        prefix: match[1],
        gap: match[2],
        suffix: match[3],
      });

      lastIndex = match.index + match[0].length;
    }

    // Add any remaining text that follows the last match
    if (lastIndex < text.length) {
      segments.push({ type: "text", content: text.substring(lastIndex) });
    }

    return segments;
  };

  function numberToWord(number) {
    const words = [
      "first",
      "second",
      "third",
      "fourth",
      "fifth",
      "sixth",
      "seventh",
      "eighth",
      "ninth",
      "tenth",
      "eleventh",
      "twelfth",
      "thirteenth",
      "fourteenth",
      "fifteenth",
      "sixteenth",
      "seventeenth",
      "eighteenth",
      "nineteenth",
      "twentieth",
    ];

    return words[number - 1] || "";
  }

  const handleBegin = () => {
    setShowCoverSheet(false);
    setMsgCode(0);
    setCurrentQuestionIndex(0);
    if (signaturePanelRef.current) {
      signaturePanelRef.current.saveSignature();
    }
    clearSignature();
  };


  // =================================================== //
  // ============== Page Component Start ============== //
  // ================================================= //


  function PageComponent({
      data,
      currentQuestionIndex,
      totalQuestions,
      dataLength,
      classId
  }) {

    const answers = useSelector((state) => state.assessment.answers);
    const [localAssessmentState, setLocalAssessmentState] = useState({
      answers: {},
    });

    // useEffect(() => {
    //   const currentAnswer = localAssessmentState.answers[data.questionNum];
    //   if (currentAnswer?.a1) setSelectedRadio(0);
    //   else if (currentAnswer?.a2) setSelectedRadio(1);
    // }, [data, localAssessmentState]);

    useEffect(() => {
      // Fetch stored answers from Redux, defaulting to an empty object
      const storedAnswers = answers[data.questionNum] || {};
    
      // Initialize local assessment state
      setLocalAssessmentState((prevState) => ({
        ...prevState,
        answers: {
          ...prevState.answers,
          [data.questionNum]: storedAnswers, // Use stored answers or default
        },
      }));
    
      if (data.QType === 5) {
        // Build initial order for QType 5 from stored answers or default data
        const rebuiltOrder = Object.keys(storedAnswers).length
          ? Object.keys(storedAnswers)
              .filter((key) => /^a\d+$/.test(key)) // Only include answer keys (e.g., 'a1', 'a2')
              .map((key) => ({
                id: parseInt(key.slice(1)), // Extract the numeric part for id
                text: storedAnswers[key],  // Get the corresponding text
              }))
              .sort((a, b) => a.id - b.id) // Ensure the order is maintained
          : convertToAnswers(data); // Fallback to default answers from `data`
    
        setQtypeFiveOrder(rebuiltOrder);
      }
    }, [data.questionNum, answers, data.QType]);

    const handleRadioChange = (index) => {
      setSelectedRadio(index);
      // Wipe out previous answer if it exists
      setLocalAssessmentState((prev) => {
        const updated = { ...prev };

        // Clean slate for this question
        if (updated.answers?.[data.questionNum]) {
          updated.answers[data.questionNum] = {};
        }

        // Now set the new answer
        updated.answers = {
          ...updated.answers,
          [data.questionNum]: {
            [`a${index + 1}`]: true,
          },
        };

        return updated;
      });
    };
   
    const handleInputChange = (qNum, field, value) => {
      setLocalAssessmentState((prevState) => ({
        ...prevState,
        answers: {
          ...prevState.answers,
          [qNum]: {
            ...prevState.answers[qNum],
            [field]: value,
          },
        },
      }));
    };

    const [checkedBoxes, setCheckedBoxes] = useState(Array(20).fill(false));

    const resetState = () => {
      setCheckedBoxes(Array(20).fill(null));
      setMsgCode(0);
    };

    const handleCheckboxChange = (index, value) => {
      // Update the local checked boxes array
      const newCheckedBoxes = [...checkedBoxes];
      newCheckedBoxes[index] = value;
      setCheckedBoxes(newCheckedBoxes);
    
      const field = `a${index + 1}`;
      // Update the local assessment state for the current question
      setLocalAssessmentState((prevState) => ({
        ...prevState,
        answers: {
          ...prevState.answers,
          [data.questionNum]: {
            ...prevState.answers[data.questionNum], // Preserve existing answers for the current question
            [field]: value,
          },
        },
      }));
    };

    const handleNextQuestion = () => {

      // log({currentQuestionIndex,dataLength})

      let index = 1

      if (currentQuestionIndex === dataLength - 1) {
        if (mode === "assessment") {

          if (practicalData.length > 0) {
            setMode("practical");
            setCurrentQuestionIndex(0);
            setCurrentData(practicalData)
          }

          setMode("completed");

          setCurrentQuestionIndex(0);
          setCurrentData(practicalData)

        } else if (mode === "practical") {
          setMode("completed");
        } else if (mode === "completed") {
          setMode("survey");
          setCurrentQuestionIndex(0);
        }
        return;
      }

      if (data.QType === -1){
        if (currentData[currentQuestionIndex + 2].QType === 14) {
          index = 3
        } else {
          index = 2
        }
      }

      setCurrentQuestionIndex((prevIndex) => prevIndex + index);
      resetState();
    };

    const handlePreviousQuestion = () => {
      if (currentQuestionIndex > 0) {
        
        const storedAnswers = answers[currentQuestionIndex - 1] || {};
        
        setLocalAssessmentState((prevState) => ({
          ...prevState,
          answers: {
            ...prevState.answers,
            [currentQuestionIndex - 1]: storedAnswers,
          },
        }));

        if (data.QType === 1 || data.QType === 2) {
          const updatedCheckboxes = Array(20).fill(false);  
          Object.entries(storedAnswers).forEach(([key, value]) => {
            const index = parseInt(key.slice(1)) - 1;
            updatedCheckboxes[index] = value;
          });
          setCheckedBoxes(updatedCheckboxes); 
        }
        
        setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
      }
      resetState();
    };

    const handleFormSubmit = (e) => {
      e.preventDefault();

      if (data.QType === -1 || data.isSupplementary) {
        // print('Skipped Form Submit')
        handleNextQuestion();
        return;
      }

      const hasCheckboxes = Array.from({ length: 20 }).some(
        (_, i) => data[`c${i + 1}`]
      );
      const hasSelectedRadio = selectedRadio !== null;
      // const hasTextInputs = Array.from({ length: 20 }).some((_, i) => typeof data[`c${i + 1}`] === 'string' && data[`c${i + 1}`].trim() !== "");

      const isTextInputQType2 =
        data.QType === 2 &&
        Array.from({ length: 20 }).some((_, i) => {
          const choice = data[`c${i + 1}`];
          return typeof choice === "string" && choice.includes("____");
        });

      const hasCheckedCheckboxes =
        hasCheckboxes &&   
        Object.values(localAssessmentState.answers[data.questionNum] || {}).some(
          (isChecked) => isChecked
        );
      const shouldSubmit =
        hasCheckedCheckboxes ||
        hasSelectedRadio ||
        isTextInputQType2 ||
        data.QType !== 2;

      if (!shouldSubmit) {
        setIsValid(false);
      }

      // log({
      //   hasCheckedCheckboxes,
      //   isTextInputQType2,
      //   shouldSubmit,
      //   hasSelectedRadio,
      //   isValid,
      // });

      const formData = new FormData(e.target); // e.target is your form element

      // Convert FormData to a plain object and handle dual-input concatenation
      const formDataObj = {};

      formData.forEach((value, key) => {
        if (key.includes("part1") || key.includes("part2")) {
          // If part of a split input, concatenate appropriately
          const baseKey = key.slice(0, -5); // Remove 'part1' or 'part2' to get the base key
          formDataObj[baseKey] = formDataObj[baseKey]
            ? `${formDataObj[baseKey]}|${value}`
            : value;
        } else {
          // Handle regular inputs
          formDataObj[key] = value;
        }
      });

      // Specifically handle QType 5 order, ensuring not to add empty fields
      if (data.QType === 5) {
        qTypeFiveOrder.forEach((item, index) => {
          if (item.text.trim() !== "") {
            // Only add if text is not empty
            formDataObj[`a${index + 1}`] = item.text;
          }
        });

        // Clear remaining 'c' keys if less than 20 items
        for (let i = qTypeFiveOrder.length + 1; i <= 20; i++) {
          if (formDataObj[`a${i}`]) {
            delete formDataObj[`a${i}`]; // Remove key from object if it exists
          }
        }
      }

      // Store student answers in the global store
       const answersToStore = {};
       Object.keys(formDataObj).forEach((key) => {
         if (/^a\d+$/.test(key)) {
           answersToStore[key] = formDataObj[key];
         }
       });
       
       dispatch(
         saveAnswers({
           qNum: data.questionNum,
           answers: answersToStore,
         })
       );

      // log({ classId });

      const endpoint = "assessment/student_answer";
      const postData = {
        postedData: formDataObj,
        classId,
      };

      // log({ postData });

      if (shouldSubmit) {
        fetchApi(endpoint, postData).then((response) => {
          switch (true) {
            case response.status >= 200 && response.status < 300:
              handleNextQuestion();
              break;
            default:
              // console.error("Error submitting outcome");
              setMsg(
                response.msg ||
                  "An unexpected error occurred. The outcome was not submitted."
              );
              setMsgCode(4);
              break;
          }
        });

        setIsValid(true);
        setSelectedRadio(null);
      }
    };

    function convertToAnswers(originalObject) {
      // console.log('Original Object:', originalObject);
      const steps = [];
      // Loop up to 20 or until a null/undefined entry is found
      for (let i = 1; i <= 20; i++) {
        const key = `c${i}`;
        if (originalObject[key] === null || originalObject[key] === undefined) {
          break; // Exit the loop early if the current step is null or undefined
        }
        if (originalObject[key]) {
          // Still check if the key exists and is not empty
          steps.push({ id: i, text: originalObject[key] });
        }
      }
      // console.log('Converted Steps:', steps);
      return steps;
    }

    // Uses the above function to extract the initial order of answers
    const [qTypeFiveOrder, setQtypeFiveOrder] = useState(() =>
      convertToAnswers(data)
    ); // Using initializer function to avoid recalculations on re-renders

    let buttonText = "Next Question";

    if (data.QType === -1) {

      // log({currentData, currentQuestionIndex})

      if (currentData[currentQuestionIndex + 2]?.questionNum !== 1 || currentData[currentQuestionIndex + 2]?.isSupplementary) {
        buttonText = "Continue"
      } else {
        buttonText = "Start"
      }

    // } else if (data.isSupplementary) {
    //   buttonText = "Attempt Supplemementary";

    } else if (completed) {
      buttonText = "Finish";
    }

    const adjustTextareaHeight = (event) => {
      const textarea = event.target;
      textarea.style.height = "auto"; // Reset height to auto
      textarea.style.height = textarea.scrollHeight + "px"; // Set the height to scrollHeight
    };

    const handleReorder = (e) => {
      const updatedOrder = [...qTypeFiveOrder];
      const [removed] = updatedOrder.splice(e.fromIndex, 1);
      updatedOrder.splice(e.toIndex, 0, removed);
      setQtypeFiveOrder(updatedOrder);
      // console.log(updatedOrder);

      setLocalAssessmentState((prevState) => ({
        ...prevState,
        answers: {
          ...prevState.answers,
          [data.questionNum]: updatedOrder.reduce((acc, item, index) => {
            acc[`a${index + 1}`] = item.text;
            return acc;
          }, {}),
        },
      }));
    };

    const isTrueOrFalse = (c1, c2) => {
      const lowerC1 = c1.toLowerCase().trim();
      const lowerC2 = c2.toLowerCase().trim();
      return (
        (lowerC1 === "true." && lowerC2 === "false.") ||
        (lowerC1 === "false." && lowerC2 === "true.")
      );
    };

    const isYesOrNo = (c1, c2) => {
      const lowerC1 = c1.toLowerCase().trim();
      const lowerC2 = c2.toLowerCase().trim();
      return (
        (lowerC1 === "yes" && lowerC2 === "no") ||
        (lowerC1 === "no" && lowerC2 === "yes")
      );
    };

    const questionValues = Object.fromEntries(
        Object.entries(data)
            .filter(([key, value]) => key.startsWith("c") && value !== null)
    );

    const pictureLink = () => {
      const regex = /src=#code#\/([\w.-]+\.\w+)/g;
      const matches = [...data.question.matchAll(regex)];

      // Create full image URLs
      return matches.map(match =>
          `https://tp.edu.au/portal/tpro-files/backend/media/resources/assess-pics/${modCode}/${match[1]}`
      );
    };

    // Auto-generate filename using user details (assuming they're already accessible via your user state)
    const generatedFileName = `${user.last}-${user.first}_${data.c1}.pdf`;

    const server = () => {
      let api;

      switch (environment) {
        case 'production':
          api = 'https://api.trainingprofessionals.com.au';
          break;
        case 'development':
          api = 'https://10.1.10.49:5001';
          break;
        default:
          api = 'https://localhost:3001';
      }

      return api;
    }

    // Reuse the existing upload function with slight adjustments for modCode inclusion
    const uploadFile = (file) => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("file", file, generatedFileName); // Auto-generated filename
        formData.append("studentId", studentId);
        formData.append("classId", classId);
        formData.append("modCode", modCode);

        const req = new XMLHttpRequest();
        req.open("POST", `${server()}/student/upload`);
        req.withCredentials = true;

        req.onload = () => {
          if (req.status >= 200 && req.status < 300) {
            resolve(true);
            print('✅ Upload successful!');
          } else {
            reject(`Failed to upload file. Status: ${req.status}`);
            print(`⚠️ Upload failed. Try again!`);
          }
        };

        req.onerror = () => {
          reject("Failed to upload file.");
          print(`⚠️ Upload failed. Check your connection!`);
        };

        req.send(formData);
      });
    };

    // Adjusted Dropzone for our QType 11 uploads
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
      onDrop: async (acceptedFiles, rejectedFiles) => {
        print("");

        if (rejectedFiles.length > 0) {
          print(`⚠️ Unsupported file(s). Please upload PDF only.`);
        }

        if (acceptedFiles.length > 0) {
          const file = acceptedFiles[0];

          if (file.type !== "application/pdf") {
            print("⚠️ PDF files only!");
            return;
          }

          await uploadFile(file);
        }
      },
      multiple: false,
      accept: {
        'application/pdf': [],
      },
    });

    return (
        <div style={{width: showMobile ? '95%' : '', margin: '0 auto'}}>
          {/*<CustomAlert msg={msg} msgCode={msgCode} />*/}

          <h1><strong>Assessment Task {data.section}</strong></h1>

          <form onSubmit={handleFormSubmit} id={"form"} autoComplete={"off"}>
            {data.QType !== -1 &&
                <h1 style={{width: "100%"}}>
                  <strong>

                    {`Question ${data.questionNum}`}
                    {/*<em> of </em>*/}
                    <span
                        style={{fontSize: 32, fontWeight: "normal", color: "#000"}}
                    >
                    <em> of </em>
                  </span>
                    {totalQuestions}
                  </strong>
                </h1>
            }

            {pictureLink && (
                <div>
                  {pictureLink().map((imgSrc, idx) => (
                      <img key={idx} src={imgSrc} alt={`Assessment image ${idx + 1}`} style={{maxWidth: '100%', margin: '5px', marginTop: 50}} />
                  ))}
                </div>
            )}

            {data.feedback && (
                <div style={{marginTop: 25}}>
                  <h5
                      style={{textAlign: "start", marginLeft: 5, marginBottom: -55}}
                  >
                    Trainer Feedback:
                  </h5>
                  <CustomAlert msg={data.feedback} msgCode={3}/>
                </div>
            )}

            {/*{data.QType === -1 && (*/}
            {/*    <div className="intro-page">*/}

            {/*      /!*<h1>*!/*/}
            {/*      /!*  <strong>Assessment Task {data.section}</strong>*!/*/}
            {/*      /!*</h1>*!/*/}

            {/*      <div style={{margin: "40px 0", padding: "15px"}}>*/}
            {/*        <h2 style={{textAlign: "left"}}>*/}
            {/*          /!*<strong>{formatString(data.question)}</strong>*!/*/}
            {/*          <strong>dangerouslySetInnerHTML={{ __html: formatString(data.question) }}</strong>*/}
            {/*        </h2>*/}
            {/*      </div>*/}

            {/*      <div style={{padding: "15px", textAlign: "left"}}>*/}
            {/*        <h3 style={{whiteSpace: "pre-line"}}>*/}
            {/*          /!*{formatString(currentData[currentQuestionIndex + 1].question)}*!/*/}
            {/*          dangerouslySetInnerHTML={{ __html: formatString(currentData[currentQuestionIndex + 1].question) }}*/}
            {/*        </h3>*/}
            {/*      </div>*/}

            {/*      {currentData[currentQuestionIndex + 2].QType === 14 && (*/}
            {/*          <div style={{padding: "15px", textAlign: "left"}}>*/}
            {/*            <h3 style={{whiteSpace: "pre-line"}}>*/}
            {/*              /!*{formatString(currentData[currentQuestionIndex + 2].question)}*!/*/}
            {/*              dangerouslySetInnerHTML={{ __html: formatString(currentData[currentQuestionIndex + 2].question) }}*/}
            {/*            </h3>*/}
            {/*          </div>*/}
            {/*      )}*/}


            {/*    </div>*/}
            {/*)}*/}

            {data.QType === -1 && (
                <div className="intro-page">

                  <div style={{ margin: "40px 0", padding: "15px" }}>
                    <h2 style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: `<strong>${formatString(data.question)}</strong>` }}></h2>
                  </div>

                  <div style={{ padding: "15px", textAlign: "left" }}>
                    <h3 style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: formatString(currentData[currentQuestionIndex + 1].question) }}></h3>
                  </div>

                  {currentData[currentQuestionIndex + 2].QType === 14 && (
                      <div style={{ padding: "15px", textAlign: "left" }}>
                        <h3 style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: formatString(currentData[currentQuestionIndex + 2].question) }}></h3>
                      </div>
                  )}

                </div>
            )}

            {data.QType === 0 && (
                <div className="free-range-answer">
                  {/*<h1 style={{width: '100%'}}><strong>Question {data.questionNum}*/}
                  {/*    /!*<em> of </em>*!/*/}
                  {/*    <span style={{fontSize: 32, fontWeight: 'normal', color: '#000'}}><em> of  </em></span>*/}
                  {/*    {totalQuestions}</strong>*/}
                  {/*</h1>*/}

                  <div style={{margin: "40px 0"}}>
                    {/*<h2 className="justify-text">{formatString(data.question)}</h2>*/}
                    <h2 className="justify-text" dangerouslySetInnerHTML={{ __html: formatString(data.question) }}></h2>
                  </div>

                  <textarea
                      required
                      className="textarea-answer"
                      name="a1"
                      rows={data.evidence}
                      placeholder="Type your answer here..."
                      readOnly={!!data.isSupplementary}
                      value={localAssessmentState.answers[data.questionNum]?.a1 || data.studentAnswers?.c1 || ""}
                      onChange={(e) =>
                          handleInputChange(data.questionNum, "a1", e.target.value)
                      }
                      onFocus={(e) => e.target.select()}
                  />
                </div>
            )}

            {data.QType === 1 && (
                <div className="question">
                  {/*<h1 style={{width: '100%'}}><strong>Question {data.questionNum}*/}
                  {/*    /!*<em> of </em>*!/*/}
                  {/*    <span style={{fontSize: 32, fontWeight: 'normal', color: '#000'}}><em> of  </em></span>*/}
                  {/*    {totalQuestions}</strong>*/}
                  {/*</h1>*/}

                  <div style={{margin: "40px 0"}}>
                    {/*<h2 className="justify-text">{formatString(data.question)}</h2>*/}
                    <h2 className="justify-text" dangerouslySetInnerHTML={{ __html: formatString(data.question) }}></h2>
                  </div>

                  {/* Check if it's a Yes/No question */}
                  {isYesOrNo(data.c1, data.c2) ? (
                      <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start",
                            alignItems: "start",
                          }}
                      >
                        <div
                            style={{
                              marginRight: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                        >
                          <input
                              required
                              type="radio"
                              className="radio-as-checkbox"
                              id="trueOption"
                              name={`a1`}
                              value="Yes"
                              readOnly={!!data.isSupplementary}
                              checked={localAssessmentState.answers[data.questionNum]?.a1 === "Yes"}
                              onChange={() => handleInputChange(data.questionNum, "a1", "Yes")}
                          />
                          <label htmlFor="trueOption">{data.c1}</label>
                        </div>
                        <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: 15,
                            }}
                        >
                          <input
                              required
                              type="radio"
                              className="radio-as-checkbox"
                              id="falseOption"
                              name={`a2`}
                              readOnly={!!data.isSupplementary}
                              checked={localAssessmentState.answers[data.questionNum]?.a1 === "No"}
                              onChange={() => handleInputChange(data.questionNum, "a1", "No")}
                          />
                          <label htmlFor="falseOption">{data.c2}</label>
                        </div>
                      </div>
                  ) : (
                      // Multiple choices question
                      <div>
                        {Array.from({length: 20}).map((_, i) => {
                          const choice = data[`c${i + 1}`];
                          if (choice) {
                            return (
                                <div
                                    key={i}
                                    style={{
                                      display: "flex",
                                      alignItems: "start",
                                      marginBottom: "10px",
                                    }}
                                >
                                  <input
                                      type="checkbox"
                                      className="radio-as-checkbox"
                                      id={`choice${i + 1}`}
                                      name={`a${i + 1}`}
                                      readOnly={!!data.isSupplementary}
                                      checked={localAssessmentState.answers[data.questionNum]?.[`a${i + 1}`] || data.studentAnswers?.[`c${i + 1}`]}
                                      onChange={(e) => handleCheckboxChange(i, e.target.checked)}
                                  />
                                  <label htmlFor={`choice${i + 1}`}>
                                    {formatString(choice)}
                                  </label>
                                </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                  )}
                </div>
            )}

            {data.QType === 2 && (
                <div className="question">
                  {/*<h1 style={{width: '100%'}}><strong>Question {data.questionNum}*/}
                  {/*    /!*<em> of </em>*!/*/}
                  {/*    <span style={{fontSize: 32, fontWeight: 'normal', color: '#000'}}><em> of  </em></span>*/}
                  {/*    {totalQuestions}</strong>*/}
                  {/*</h1>*/}

                  <div style={{margin: "40px 0"}}>
                    {/*<h2 className="justify-text">{formatString(data.question)}</h2>*/}
                    <h2 className="justify-text" dangerouslySetInnerHTML={{ __html: formatString(data.question) }}></h2>
                  </div>

                  {isTrueOrFalse(data.c1, data.c2) ? (
                      [data.c1, data.c2].map((choice, i) => {
                        // Only iterate over true and false
                        if (choice) {
                          return (
                              <div
                                  key={i}
                                  style={{
                                    display: "flex",
                                    alignItems: "start",
                                    marginBottom: "10px",
                                  }}
                              >
                                <input
                                    type="radio" // Keep it as radio
                                    className="radio-as-checkbox"
                                    id={`choice${i + 1}`}
                                    name={`a${i + 1}`}
                                    value={`1`}
                                    disabled={!!data.isSupplementary}
                                    checked={localAssessmentState.answers[data.questionNum]?.[`a${i + 1}`] || data.studentAnswers?.[`c${i + 1}`] || selectedRadio === i}
                                    // checked={selectedRadio === i}
                                    onChange={() => handleRadioChange(i)}
                                />
                                <label className="no-select" htmlFor={`choice${i + 1}`}>
                                  {formatString(choice)}
                                </label>
                              </div>
                          );
                        }
                        return null;
                      })

                  ) : data.c1.includes("____________") ? (
                      // Multi-answer Question with Single or Dual Input
                      <div>
                        {Array.from({length: 20}).map((_, i) => {
                          const choice = data[`c${i + 1}`];
                          if (choice) {
                            const parts = choice.split("~3");
                            return (
                                <div
                                    key={i}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginBottom: "10px",
                                      width: "100%",
                                    }}
                                >
                                  <label
                                      style={{
                                        marginRight: "10px",
                                        fontSize: 30,
                                        marginBottom: 15,
                                      }}
                                  >
                                    {i + 1}
                                  </label>

                                  {parts.length > 1 ? (
                                      // Dual-input with pre-provided first part
                                      <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            gap: "10px",
                                            width: "100%",
                                          }}
                                      >
                                      <span>
                                        <h3>{formatString(parts[0].trim())} - </h3>
                                          </span>
                                        <input
                                            required
                                            type="text"
                                            name={`a${i + 1}`}
                                            className="multi-answer"
                                            placeholder={`Type your ${numberToWord(i + 1)} answer here...`}
                                            style={{flex: 1}}
                                            readOnly={!!data.isSupplementary}
                                            value={
                                                localAssessmentState.answers[data.questionNum]?.[
                                                    `a${i + 1}`
                                                    ] || data.studentAnswers?.[`c${i + 1}`] || ""
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    data.questionNum,
                                                    `a${i + 1}`,
                                                    e.target.value
                                                )
                                            }
                                        />
                                      </div>
                                  ) : (
                                      // Single input for blank-only field
                                      <input
                                          required
                                          type="text"
                                          name={`a${i + 1}`}
                                          className="multi-answer"
                                          placeholder={`Type your ${numberToWord(i + 1)} answer here...`}
                                          style={{width: "100%"}}
                                          readOnly={!!data.isSupplementary}
                                          value={
                                              localAssessmentState.answers[data.questionNum]?.[
                                                  `a${i + 1}`
                                                  ] || data.studentAnswers?.[`c${i + 1}`] || ""
                                          }
                                          onChange={(e) =>
                                              handleInputChange(
                                                  data.questionNum,
                                                  `a${i + 1}`,
                                                  e.target.value
                                              )
                                          }
                                      />
                                  )}
                                </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                  ) : (
                      // Multiple choices question
                      <div>
                        {Array.from({length: 20}).map((_, i) => {
                          const choice = data[`c${i + 1}`];
                          if (choice) {
                            return (
                                <div
                                    key={i}
                                    style={{
                                      display: "flex",
                                      alignItems: "start",
                                      marginBottom: "10px",
                                    }}
                                >
                                  <input
                                      type="checkbox"
                                      className="radio-as-checkbox"
                                      id={`choice${i + 1}`}
                                      name={`a${i + 1}`}
                                      value={`1`}
                                      disabled={!!data.isSupplementary}
                                      checked={localAssessmentState.answers[data.questionNum]?.[`a${i + 1}`] || data.studentAnswers?.[`c${i + 1}`] || false}
                                      onChange={(e) => handleCheckboxChange(i, e.target.checked)}
                                  />
                                  {/*<label htmlFor={`choice${i + 1}`}>*/}
                                  {/*  /!*{formatString(choice)}*!/*/}
                                  {/*  /!*{choice}*!/*/}
                                  {/*</label>*/}

                                  <label className="no-select" htmlFor={`choice${i + 1}`} dangerouslySetInnerHTML={{ __html: formatString(choice) }}></label>
                                </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                  )}
                </div>
            )}

            {data.QType === 5 && (
                <div className="sequencing-question">

                  <div className="justify-text" style={{margin: "40px 0"}}>
                    <div dangerouslySetInnerHTML={{__html: data.question}}></div>
                    <div style={{margin: 15}} className="drag-n-drop-instructions">HINT: Drag and drop the items to
                      place them in the correct
                      order.
                    </div>
                    <div className="widget-container" style={{marginTop: 25}}>
                      <List
                          items={qTypeFiveOrder}
                          // readOnly={!!data.isSupplementary}
                          selectionMode="none"
                          keyExpr="id"
                          // disabled={true}
                          focusedRowEnabled={false}
                          activeStateEnabled={false}
                          focusStateEnabled={false}
                          repaintChangesOnly={false}
                          className={playAnimation ? 'animate-swap' : ''}>
                        <ItemDragging
                            draggable={!data.isSupplementary} // 👈 disable dragging
                            onReorder={(e) => {
                              if (!data.isSupplementary) {
                                handleReorder(e);
                              }
                            }}
                            // draggable={true}
                            // onReorder={handleReorder}
                            readOnly={!!data.isSupplementary}
                            selectionMode="none"
                            focusedRowEnabled={false}
                            activeStateEnabled={false}
                            allowReordering={true}
                            repaintChangesOnly={false}
                            group="tasks"
                            data="doingTasks"
                            // onDragStart={onDragStart}
                            // onAdd={onAdd}
                            // onRemove={onRemove}
                        />
                      </List>

                      {/*<input type="hidden" name="draggedItemsOrder" id="draggedItemsOrder" />*/}
                    </div>
                  </div>
                </div>
            )}

            {data.QType === 6 && (
                <div className="fill-in-the-gaps">
                  {/*<h1 style={{width: '100%'}}><strong>Question {data.questionNum}*/}
                  {/*    /!*<em> of </em>*!/*/}
                  {/*    <span style={{fontSize: 32, fontWeight: 'normal', color: '#000'}}><em> of  </em></span>*/}
                  {/*    {totalQuestions}</strong>*/}
                  {/*</h1>*/}
                  <div className="justify-text" style={{margin: "40px 0"}}>
                    {" "}
                    {/* Adjusting bottom and left margins */}
                    <div
                        dangerouslySetInnerHTML={{__html: data.question}}
                        style={{marginBottom: "50px"}}
                    />
                    {/* Gap between question and answers */}
                    {Array.from({length: 20}, (_, index) => `c${index + 1}`).map(
                        (field, index) => {
                          const value = data[field];
                          return value ? (
                              <div key={index} style={{marginLeft: 40}}>
                                <strong>{value[0]}</strong>
                                <input
                                    required
                                    type="text"
                                    name={`a${index + 1}`}
                                    className="gap-input"
                                    placeholder="..."
                                    readOnly={!!data.isSupplementary}
                                    style={{
                                      width: `${(value.length - 1) * 20}px`,
                                      textTransform: 'lowercase'
                                    }} // Adjust width based on content length minus the first character
                                    value={
                                        localAssessmentState.answers[data.questionNum]?.[`a${index + 1}`] || data.studentAnswers?.[`c${index + 1}`] || ""
                                    }
                                    onChange={(e) =>
                                        handleInputChange(data.questionNum, `a${index + 1}`, e.target.value.toLowerCase())
                                    }
                                    inputMode="text"
                                    autoCapitalize="none"
                                />
                              </div>
                          ) : null;
                        }
                    )}
                  </div>
                </div>
            )}

            {data.QType === 7 && (
                <div className="fill-in-the-gaps">
                  {/*<h1 style={{width: '100%'}}><strong>Question {data.questionNum}*/}
                  {/*    /!*<em> of </em>*!/*/}
                  {/*    <span style={{fontSize: 32, fontWeight: 'normal', color: '#000'}}><em> of  </em></span>*/}
                  {/*    {totalQuestions}</strong>*/}
                  {/*</h1>*/}

                  <div className="justify-text" style={{marginTop: 25}}>
                    {(() => {
                      let inputCounter = 1;
                      let boldText = false;
                      return formatStringSafely(data.question)
                          .split("\n")
                          .map((line, index) => (
                              <p key={index}>
                                {splitQuestion(line).map((segment, innerIndex) => {
                                  if (segment.type === "text") {
                                    if (
                                        segment.content.includes(
                                            "(Fill in the blanks) - "
                                        )
                                    ) {
                                      const parts = segment.content.split(
                                          "(Fill in the blanks) - "
                                      );
                                      boldText = true; // Start bold text after the special phrase
                                      return (
                                          <React.Fragment key={innerIndex}>
                                            <span>{parts[0]}(Fill in the blanks)</span>
                                            <strong className="small-strong">
                                              {parts[1].replace(/-/g, " ")}
                                            </strong>{" "}
                                            {/* Remove hyphens */}
                                          </React.Fragment>
                                      );
                                    }
                                    return boldText ? (
                                        <strong key={innerIndex} className="small-strong">
                                          {segment.content.replace(/-/g, " ")}
                                        </strong>
                                    ) : (
                                        <span key={innerIndex}>{segment.content}</span>
                                    );
                                  } else if (segment.type === "gap") {
                                    const inputName = `a${inputCounter++}`; // Increment the counter for each input
                                    const inputAnswer = `c${inputCounter}`;
                                    return (
                                        <span
                                            key={innerIndex}
                                            style={{whiteSpace: "nowrap"}}
                                        >
                                    <input
                                        required
                                        name={inputName}
                                        className="gap-input"
                                        placeholder="..."
                                        readOnly={!!data.isSupplementary}
                                        style={{
                                          width: `${segment.content.length * 20}px`,
                                          textTransform: 'lowercase'
                                        }} // Adjust width based on content length
                                        value={localAssessmentState.answers[data.questionNum]?.[inputName] || data.studentAnswers?.[inputAnswer] || ""}
                                        onChange={(e) =>
                                            handleInputChange(data.questionNum, inputName, e.target.value.toLowerCase())
                                        }
                                        autoCapitalize="none"
                                    />
                                  </span>
                                        );
                                      } else if (segment.type === "word_with_gap") {
                                        const inputName = `a${inputCounter++}`;
                                        const inputAnswer = `c${inputCounter}`;
                                        return (
                                            <span
                                                key={innerIndex}
                                                style={{whiteSpace: "nowrap"}}
                                            >
                                    <span>{segment.prefix}</span>
                                    <input
                                        required
                                        name={inputName}
                                        className="gap-input"
                                        // placeholder={inputName}
                                        placeholder="..."
                                        readOnly={!!data.isSupplementary}
                                        style={{
                                          width: `${segment.gap.length * 20}px`,
                                        }} // Adjust width based on content length
                                        value={localAssessmentState.answers[data.questionNum]?.[inputName] || data.studentAnswers?.[inputAnswer] || ""}
                                        onChange={(e) =>
                                            handleInputChange(data.questionNum, inputName, e.target.value.toLowerCase())
                                        }
                                    />
                                    <span>{segment.suffix}</span>
                                  </span>
                                    );
                                  }
                                  return null;
                                })}
                              </p>
                          ));
                    })()}
                  </div>
                </div>
            )}

            {/*{data.QType === 11 && (*/}
            {/*    <div className="upload-evidence">*/}
            {/*      <div className="justify-text" style={{ margin: "40px 0" }}>*/}

            {/*        /!* Display the question text directly *!/*/}
            {/*        <div dangerouslySetInnerHTML={{ __html: data.question }} />*/}

            {/*        /!* File Naming Instruction (c1) *!/*/}
            {/*        <div className="upload-instruction">*/}
            {/*          <strong>File must be named exactly:</strong><br />*/}
            {/*          <code>*/}
            {/*            {`${user.last}-${user.first}_${modCode}_${data.c1.split('~3')[1]}.pdf`}*/}
            {/*          </code>*/}
            {/*        </div>*/}

            {/*        /!* Must Include Instructions (c2) *!/*/}
            {/*        <div className="upload-notes">*/}
            {/*          <strong>Your file <em>must</em>:</strong>*/}
            {/*          <ul>*/}
            {/*            {data.c2.split('#').map((item, idx) => (*/}
            {/*                <li key={`must-${idx}`}>{item.trim()}</li>*/}
            {/*            ))}*/}
            {/*          </ul>*/}
            {/*        </div>*/}

            {/*        /!* Must Not Include Instructions (c3) *!/*/}
            {/*        <div className="upload-notes">*/}
            {/*          <strong>Your file <em>must not</em>:</strong>*/}
            {/*          <ul>*/}
            {/*            {data.c3.split('#').map((item, idx) => (*/}
            {/*                <li key={`mustnot-${idx}`}>{item.trim()}</li>*/}
            {/*            ))}*/}
            {/*          </ul>*/}
            {/*        </div>*/}

            {/*        /!* File Upload Box *!/*/}
            {/*        <div className="classroom-upload-zone" {...getRootProps()}>*/}
            {/*          <input {...getInputProps()} />*/}
            {/*          <FiUpload className="upload-icon" />*/}
            {/*          {isDragActive ? (*/}
            {/*              <p className="upload-text">Drop your file here...</p>*/}
            {/*          ) : (*/}
            {/*              <>*/}
            {/*                <p className="upload-text">*/}
            {/*                  Drag & drop your file here or <span className="browse-files">Browse files</span>*/}
            {/*                </p>*/}
            {/*                <p className="upload-info">Max size: 25 MB • PDF files only</p>*/}
            {/*              </>*/}
            {/*          )}*/}
            {/*        </div>*/}

            {/*      </div>*/}
            {/*    </div>*/}
            {/*)}*/}

            {data.QType === 11 && (
                <div className="upload-evidence">

                  {/* Render Question Text */}
                  <div className="justify-text" style={{ margin: "40px 0" }}>
                    <div dangerouslySetInnerHTML={{ __html: data.question }} />
                  </div>

                  {/* Display Instructions */}
                  <div className="upload-instructions">
                    <h3>File must be named exactly:</h3>
                    <p style={{ fontWeight: "bold", fontSize: '1rem' }}>{generatedFileName}</p>

                    <h3>✅ Your file <strong>must</strong> include:</h3>
                    <ul>
                      {data.c2.split("#").map((mustHave, idx) => (
                          <li key={`must-${idx}`}>{mustHave}</li>
                      ))}
                    </ul>

                    <h3>❌ Your file <strong>must not</strong> include:</h3>
                    <ul>
                      {data.c3.split("#").map((mustNot, idx) => (
                          <li key={`must-not-${idx}`}>{mustNot}</li>
                      ))}
                    </ul>
                  </div>

                  {/* Existing upload UI */}
                  <div className="classroom-upload-zone" {...getRootProps()} style={{ marginTop: 20 }}>
                    <input {...getInputProps()} />
                    <FiUpload className="upload-icon" style={{ fontSize: 40, margin: 10 }} />
                    {isDragActive ? (
                        <p className="upload-text">📥 Drop PDF file here...</p>
                    ) : (
                        <>
                          <p className="upload-text">
                            Drag & drop your PDF here, or <span className="browse-files">browse files</span>
                          </p>
                          <p className="upload-info">PDF only • Up to 25 MB</p>
                        </>
                    )}
                  </div>

                  {/* Alert Message for Upload Status */}
                  {/*{alertMessage && (*/}
                  {/*    <div className="alert" style={{ marginTop: 20 }}>*/}
                  {/*      {alertMessage}*/}
                  {/*      <button onClick={() => print("")}>✖️</button>*/}
                  {/*    </div>*/}
                  {/*)}*/}

                </div>
            )}


            {/*... other rendering logic for other QTypes can go here */}

            {/* The Button... OF DOOOOOM!!! */}
            {!isValid && <p>You must select at least one option!</p>}

            {/*{data.isSupplementary && (*/}
            {/*    <div style={{ display: "flex", alignItems: "end", justifyContent: "flex-end", marginRight: 50 }}>*/}
            {/*      <label style={{ marginRight: "10px", padding: 10 ,fontSize: "18px" }}>*/}
            {/*        {data.outcome === "S" ? "Satisfactory" : "Your answer is incorrect"}*/}
            {/*      </label>*/}
            {/*      <span*/}
            {/*          style={{*/}
            {/*            color: data.outcome === "S" ? "green" : "red",*/}
            {/*            fontSize: "50px" // Reduced size for the ✔ or ✘*/}
            {/*          }}*/}
            {/*      >*/}
            {/*        {data.outcome === "S" ? "✔" : "✘"}*/}
            {/*      </span>*/}
            {/*    </div>*/}
            {/*)}*/}

            {data.isSupplementary && (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "end", justifyContent: "flex-end", marginRight: 50 }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label style={{ marginRight: "10px", padding: 10, fontSize: "18px" }}>
                      {data.outcome === "S" ? "Satisfactory" : "Your answer is incorrect"}
                    </label>
                    <span
                        style={{
                          color: data.outcome === "S" ? "green" : "red",
                          fontSize: "50px" // Size for the ✔ or ✘
                        }}
                    >
                      {data.outcome === "S" ? "✔" : "✘"}
                    </span>
                  </div>

                  {/* Warning message */}
                  <div style={{ marginTop: "5px", fontSize: "14px", color: "red", maxWidth: 550}}>
                    ⚠️ You can not change your answers here. You will be provided with an oportunity to re-attempt a supplementary question at the end of the assessment.
                  </div>
                </div>
            )}

            <div
                className="btn-container"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                  zIndex: 10000,
                }}
            >
              {/* Previous Question button with type="button" to prevent form submission */}
              <button
                  className={!showMobile ? 'btn-primary' : 'btn-mobile'}
                  type="button" // This prevents it from submitting the form
                  style={{
                    padding: "10px 20px",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                  onClick={() => handlePreviousQuestion()}
              >
                Previous Question
              </button>

              {/* Next (Submit) button */}
              <button
                  className={!showMobile ? 'btn-primary' : 'btn-mobile'}
                  style={{
                    padding: "10px 20px",
                    fontSize: "16px",
                    cursor: "pointer",
                    zIndex: 10000,
                  }}
                  type="submit"
              >
                {buttonText}
              </button>
            </div>

            {/*<div className="btn-container" style={{marginTop: '20px', zIndex: 10000}}>*/}
            {/*    <button className={"btn-primary"} style={{zIndex: 10000}} type="submit">{buttonText}</button>*/}
            {/*</div>*/}

            <input type="hidden" name="autoMarking" value={autoMarking}/>
            <input type="hidden" name="qNum" value={data.questionNum}/>
            <input type="hidden" name="questionId" value={data.id}/>
            <input type="hidden" name="apprenticeModsId" value={apprenticeModsId}/>
            <input type="hidden" name="certId" value={certId}/>
            <input type="hidden" name="modId" value={modId}/>
            <input type="hidden" name="classId" value={classId}/>
            <input type="hidden" name="questionCount" value={totalQuestions}/>
            <input type="hidden" name="studentId" value={user.roleId}/>
            <input type="hidden" name="section" value={data.section}/>
            {/*<input type="hidden" name="outcome" value="NYM"/>*/}
            <input type="hidden" name="qType" value={data.QType}/>
            <input type="hidden" name="question" value={data.question}/>
            {/*<input type="hidden" name="answer" value={data.answer}/>*/}
            <input type="hidden" name="version" value={data.version}/>
            <input type="hidden" name="questionValues" value={JSON.stringify(questionValues)}/>
          </form>
        </div>
    );
  }

  return (
            <div style={{padding: "50px 0", position: "relative"}}>
              {!showMobile && <Bluey/>}

              <div
                  className={!showMobile ? "page-common-letter" : ""}
                  style={{padding: !showMobile ? 40 : 0}}
              >
                <CustomAlert msg={msg} msgCode={msgCode}/>

                {completed ? (
                    <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                      <div>
                        <h1>Thank you. You have now finished this assessment!</h1>
            </div>
            <div
              className="btn-container"
              style={{
                margin: "0 0",
                padding: "10px 20px",
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
            </div>
          </div>
        ) : showCoverSheet ? (
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            {showMobile ? (
              // Mobile-specific components
              <div>
                <div>
                  <MobilePDFHandler
                    ref={mobileSignaturePanelRef}
                    annotations={annotations}
                    certName={data.certName}
                    certCode={data.certCode}
                    modCode={modCode}
                    modName={modName}
                    studentId={user.roleId}
                    modId={modId}
                    apprenticeModsId={apprenticeModsId}
                    onSignButtonClick={handleOpenMobileSignaturePanel}
                    selectedDate={dateSigned}
                  />

                </div>

                <div>
                  <MobileSignaturePanel
                    btnText="Confirm"
                    instructions={instructionsText}
                    showPanel={showMobileSignature}
                    showCanvas={showMobileSignatureCanvas}
                    onCancel={() => setShowMobileSignature(false)}
                    handleSubmit={() => {
                      // console.log("Saving signature...");
                      if (mobileSignaturePanelRef.current) {
                        mobileSignaturePanelRef.current.saveSignature(); // No need to await
                      }
                      setShowMobileSignature(false);
                      handleBegin();
                    }}  
                    onCloseCanvas={() => setShowMobileSignatureCanvas(false)}
                    selectedDate={dateSigned} 
                    onDateChange={(selectedDate) => setDateSigned(selectedDate)}     
                  />
                </div>

                <div className="begin-assessment">
                  <button
                    className="btn-primary begin-assessment"
                    onClick={handleBegin}
                  >
                    Begin Assessment
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <SignaturePanel
                  ref={signaturePanelRef}
                  annotations={annotations}
                  certName={data.certName}
                  certCode={data.certCode}
                  modCode={modCode}
                  modName={modName}
                  studentId={user.roleId}
                  modId={modId}
                  apprenticeModsId={apprenticeModsId}
                />
                <div
                  className="btn-container"
                  style={{
                    margin: "0 0",
                    padding: "10px 20px",
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    className="btn-primary"
                    style={{
                      padding: "10px 20px",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                    onClick={handleBegin}
                  >
                    Begin Assessment
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : mode === "assessment" ? (
          <div>

            {Object.keys(actualAssessmentData).length > 0 ? (
                <div>
                  <PageComponent
                      data={
                        actualAssessmentData[currentQuestionIndex]
                      }
                      currentQuestionIndex={currentQuestionIndex}
                      totalQuestions={totalQuestions}
                      dataLength={actualAssessmentData.length}
                      classId={classId}
                  />

                </div>
            ) : (
                <p>No assessment data available</p>
            )}
          </div>
        ) : mode === "practical" ? (
            <div>
              <h1>Practical Tasks</h1>
            </div>
        ) : mode === "completed" ? (
          <>{navigate("/feedback")}</>
        ) : (
          <div>
            <h1>Unknown Mode</h1>
            <p>Unable to render assessment. Please contact your trainer. </p>
          </div>
        )}
      </div>
    </div>
  );
}

// =============================== To Do =================================== //

// Question flagging system
// Status/notification system

// ** IMPORTANT: Add versioning to saved questions... ** (Done)

// =============================== Bugs =================================== //

// Supplementary questions: Students answers not appearing on click previous... (Maybe Fixed)
// QType 7 supplementary feed back question (Read only) do not show the students answers (fixed)
// Twould seem that answers to qType 7 Questions are no longer saved... and that makes daddy very unhappy...
// Students updated answer on previous is not being saved

