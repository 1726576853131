// Purpose: To define all the constants used in the application in 1 location.
///////////////////////////// RECAPTCHA SITE KEY /////////////////////////////
// This must be moved to a .env file in production in the BE and be fetched from the server - this is only the FE key
export const RECAPTCHA_FE_KEY = '6LdJq9cqAAAAAAg__AA1ohlenGWsLUX2vcTsDxUQ';

///////////////////////////// ENDPOINTS & EMAILS /////////////////////////////
export const API_URL = 'https://api.tp.edu.au/';
export const EMAIL_NOTIFICATIONS = 'system/email';
export const SMS_NOTIFICATIONS = 'system/sms';
export const EMAIL_PW_RESET = 'email/pw_reset';
export const SMS_PW_RESET = 'sms/pw_reset';

export const ADMIN_EMAIL = 'craig@tp.edu.au';
export const SUPPORT_EMAIL = 'support@tp.edu.au';
export const SIMON_EMAIL = 'simondev@tp.edu.au';

///////////////////////////// PAGES UNDER CONSTRUCTION /////////////////////////////
export const underConstructionPages = [
  // '/contact',
  // '/study/my-profile',
  '/enrol',
  // '/study/classroom(LIVE)',
  // '/study/pre-enrolment',
  // '/study/enrolment',
  // '/study/diary',
  // '/password-recovery',
  '/portal/administration/setup',
  '/portal/history',
  '/files',
  '/cloud-storage',
  '/chat',
  '/calendar',
];


///////////////////////////// HEARTBEAT CONSTANTS /////////////////////////////
export const MAX_FAILURES = 3; // Maximum number of failures before showing the refresh login modal
export const HEARTBEAT_INTERVAL =  30000; // 30 seconds
export const KEY_REFRESH_INTERVAL = 30000; // 30 seconds


/////////////////////////////  ACTIVITY LOGGER /////////////////////////////
export const ACTIVITY_TIMEOUT = 1200000; // 20 minutes in milliseconds (1200000)
export const COUNTDOWN = 30; // 30 seconds

//////////////////////////// FORM QUESTION TYPES /////////////////////////////
export const VALID_QUESTION_TYPES = [
  100, // Text 100 chars
  101, // Text 250 chars
  102, // Text 500 chars
  103, // Text 1000 chars
  110, // Phone #
  111, // Mobile # only
  112, // Email
  115, // Name (student/candidate)
  116, // Name (trainer/RTO rep)
  117, // Name (employer)
  118, // Name (parent/guardian)
  119, // Name (school rep)
  120, // $ amount
  // Simple address
  150, // Address line 1
  151, // Address line 2
  // Complex address - PHYSICAL
  160, //Building/property name 
  161, //Flat/unit details
  162, //Street or lot number (e.g. 205 or Lot 118) 
  163, //Street name
  165, //Suburb, locality or town
  166, //State/territory
  167, //Postcode
  // Complex address - POSTAL
  170, //Building/property name 
  171, //Flat/unit details
  172, //Street or lot number (e.g. 205 or Lot 118)
  173, //Street name
  174, //Postal delivery information (e.g. PO Box 254)
  175, //Suburb, locality or town
  176, //State/territory
  177, //Postcode

  // Numbers
  180, // Number any length
  181, // 4 digits (Oz postcode)

  // Questions with options
  201, // Single choice, dropdown
  202, // Multiple choice (checkbox)
  203, // Yes/No
  210, // Strongly Disagree to Strongly Agree (4 options)
  251, // Gender - Female(0), Male (1), Other (2)

  // Dates - these are now inputMasks not pickers - see DateInputMask.tsx
  // Can be controlled from the data by using link
  301, // Date YYYY
  302, // Date mmYYYY
  303, // Date DDmmYYYY

  400, // LeadIn
  401, // Consent - name must be valid in state or will render an error
  402, // Disclaimer/Fine print
  410, // Signature

  500, // File upload any type
  501, // File upload image only
  502, // File upload PDF only
  503, // File upload video only
  504, // File upload audio only
  510, // Upload url link with a dynamic url 
  520, // Dropdown, single choice, image upload only, maybe shared with other 520s
  600, // Signifies a question/s that are to be shown based on the option/s chosen on the previous question. The option/s are provided in the options array. 
  699, // End of conditional questions - questions after this will be shown regardless of the previous answer

  900, // email notification
  901, // sms notification
] as const;


/////////////////////////////  QUESTION TARGET GROUPS /////////////////////////////
export const VALID_TARGET_GROUPS = [
  1, // Parent / Guardian
  2, // Student / Candidate
  3, // Employer
  4, // School Rep
  5, // Trainer / RTO Rep
  10, // Admin / God
] as const;

/////////////////////////////  TP ADDRESS (used for USI search) /////////////////////////////
export const TP_ADDRESS = {"addressSuburb": "Toowong", "addressState": "QLD", "addressPostcode": "4066", "addressStreetNumber": "54", "addressStreetName": "Jephson St"};