// NOTE: We will need to address the securty concerns in the code below before it can be used in production. Also the buttons in the reset modal are for testing and need to be removed before production as we can't let users nav to the route to reset the password without the email. which should contain the link to the reset page.

import { Bluey, SpacerDiv, MobileModal, Alert } from "Components";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { useFetchApi } from "CustomHooks";
import {
  NotificationPayload,
  NotificationResponse,
  SendNotification,
} from "Services/SendNotification";
import * as CONST from "../Constants/constants";

interface PasswordRecoveryFormProps {
  setMsg: React.Dispatch<React.SetStateAction<string>>;
  setMsgCode: React.Dispatch<React.SetStateAction<0 | 1 | 2 | 3 | 4>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsReset: React.Dispatch<React.SetStateAction<boolean>>;
}

const PasswordRecoveryForm: React.FC<PasswordRecoveryFormProps> = ({
  setMsg,
  setMsgCode,
  setIsOpen,
  setIsReset,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const fetchApi = useFetchApi();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      setMsg("ReCAPTCHA not ready. Please wait and try again.");
      setMsgCode(3);
      console.warn("ReCAPTCHA not loaded.");
      return;
    }

    // Run reCAPTCHA & grab token
    // const token = await executeRecaptcha("password_recovery");
    // console.log("ReCAPTCHA token:", token);

    const payload = {
      user: { email },
      subject: `TP password reset for ${email}`,
      templateId: 1000,
      to: CONST.SIMON_EMAIL,
      body: `This is a test password reset email for the TP account associated with ${email}.`,
      type: "email" as NotificationPayload["type"],
    };

    const result = (await SendNotification(
      fetchApi,
      payload
    )) as NotificationResponse;

    setMsg(result.msg);
    setMsgCode(result.msgCode);

    if (result.success) {
      setIsReset(true);
      setIsOpen(true);
    }
  };

  return (
    <div className="password-recovery-container general-form-container">
      <SpacerDiv rem={4} />
      <form onSubmit={handleSubmit}>
        <h2>
          Please enter the email address that you used to create your account
        </h2>
        <input
          type="email"
          name="email"
          className="inputBox"
          placeholder="ENTER YOUR EMAIL ADDRESS"
          title="This will be your Username."
          onChange={handleEmailChange}
          required
        />
        <SpacerDiv rem={1} />
        <div className="link glowing-underline" onClick={() => setIsOpen(true)}>
          Forgotten your email?
        </div>
        <SpacerDiv rem={2} />
        <button className="btn-primary" type="submit">
          RESET PASSWORD
        </button>
      </form>
      <SpacerDiv rem={1} />
      <div className="flex-container">
        <button
          className="btn-primary"
          type="button"
          onClick={() => navigate(-1)}
        >
          GO BACK
        </button>
      </div>
    </div>
  );
};

const PasswordRecovery: React.FC = () => {
  const [msg, setMsg] = useState<string>("");
  const [msgCode, setMsgCode] = useState<0 | 1 | 2 | 3 | 4>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isReset, setIsReset] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleContactClick = () => {
    setIsOpen(false);
    navigate("/contact");
  };

  const handleContinueClick = () => {
    setIsOpen(false);
    setIsReset(false);
    navigate("/password-reset");
  };

  const modalContent = !isReset ? (
    <div>
      <div>
        Please contact TP or speak to your trainer if you aren't sure which
        email address you used to create the account.
      </div>
      <SpacerDiv rem={2} />
      <div className="flex-container">
        <button
          className="btn-primary"
          type="button"
          onClick={handleContactClick}
        >
          CONTACT
        </button>
        <button className="btn-primary" onClick={() => setIsOpen(false)}>
          CLOSE
        </button>
      </div>
    </div>
  ) : (
    <div>
      <div>
        If your email address is associated with a TP account, you will receive
        an email shortly with a link to reset your password.
      </div>
      <SpacerDiv rem={2} />
      <div className="flex-container">
        <button className="btn-primary" onClick={handleContinueClick}>
          CONTINUE
        </button>
      </div>
    </div>
  );

  return (
    <GoogleReCaptchaProvider reCaptchaKey={CONST.RECAPTCHA_FE_KEY}>
      <Alert msg={msg} msgCode={msgCode} timeout={2000} />
      <Bluey />
      <MobileModal isOpen={isOpen} children={modalContent} withButton={false} />
      <PasswordRecoveryForm
        setMsg={setMsg}
        setMsgCode={setMsgCode}
        setIsOpen={setIsOpen}
        setIsReset={setIsReset}
      />
    </GoogleReCaptchaProvider>
  );
};

export default PasswordRecovery;
