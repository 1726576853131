// @ts-nocheck

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Bluey from "../../Components/Bluey";
import { Row, Col } from "react-bootstrap";
import logError from "../../Components/Logger"; //  error handler
import { useUserField, useFetchApi, useWorkflow } from "CustomHooks";
import { ClassroomCard, SpacerDiv, ToDoCard } from "Components";
import { useDispatch } from "react-redux";
import { setOldWorkflowData } from "State/Reducers/oldWorkflowSlice";
import { generateFormRoute } from "HelperFunctions/generateRoute";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faFilePdf,
//   faFileImage,
//   faFileWord,
//   faFileExcel,
//   faFileAlt,
//   faFile,
// } from "@fortawesome/free-solid-svg-icons";

import "../Styles/classroom.scss";
import "./Classroom.css"

// Hardcoded data for testing
import testingData from "../../TestingDataObjects/workflow.json";
import oldTestingData from "../../TestingDataObjects/enrolment_workflow.json";


export default function Classroom() {
  // NOTE: This will become the fetch
  const workflowData = testingData;
  const oldWorkflowData = oldTestingData;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const fetchApi = useFetchApi();
  const { workflows, isLoading } = useWorkflow(testingData.workflows);
  // console.log('workflows', workflows)
  const data = location.state ? location.state.jsonObject : null;
  const [moduleList, setModuleList] = useState(JSON.parse(data.modules));
  const [certificate, setCertificate] = useState(data.certificate);
  const [classId, setClassId] = useState(data.classId);
  const [msg, setMsg] = useState(window.sessionStorage.getItem("message"));
  const [msgCode, setMsgCode] = useState(0);
  const [certCode, setCertCode] = useState();
  const roleId = useUserField("roleId");

  const handleWorkflowStart = async () => {
    if (!oldTestingData) {
      return;
    }
    await dispatch(setOldWorkflowData(oldTestingData));
  
    const route = generateFormRoute(
      oldTestingData.workflowName,
      oldTestingData.currentForm  
    );

    navigate(route);
  };
  

  // // The fetch when the endpoint is ready ... can't do this on login as we need the classId I think
  // const fetchWorkflowData = async (roleId, classId) => {
  //     try {
  //         const response = await fetchApi("student/workflow", { role_id: roleId, classId: classId });

  //         if (response.status >= 200 && response.status < 300) {
  //         setWorkflowData(response.responseData);
  //         setMsgCode(4);
  //         return response.responseData;
  //         } else {
  //         setMsg(response.msg);
  //         setMsgCode(1);
  //         return null;
  //         }
  //     } catch (error) {
  //         console.error("Fetch failed or fetch function is unreachable:", error);
  //         setMsg("An unexpected error occurred. Contact your administrator " + error);
  //         setMsgCode(1);
  //         return null;
  //     }
  // };

  // useEffect(() => {
  //     const getWorkflowData = async () => {
  //         const workflows = await fetchWorkflowData(roleId, classId);
  //         if (workflows) {
  //             console.log("Workflows:", workflows);
  //             setWorkflowData(workflows);
  //         }
  //     }
  //     getWorkflowData();
  // }, [roleId, classId]);

  // useEffect(() => {
  //   console.log('Class ID in Classroom.js: ', classId)
  // }, [classId]);

  useEffect(() => {
    fetchCertDetails(moduleList[0].certID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     navigate("/login");
  //   }
  // }, [isLoggedIn, navigate]);

  // State to manage open/closed state of each accordion row
  const [expandedRows, setExpandedRows] = useState({});

  // Function to toggle the accordion row's open/closed state
  // const toggleAccordion = (index) => {
  //   setExpandedRows((prevState) => ({
  //     ...prevState,
  //     [index]: !prevState[index],
  //   }));
  // };

  // Utility function to get the appropriate icon
  // const getIconForFileType = (mimeType) => {
  //   switch (true) {
  //     case mimeType.includes("pdf"):
  //       return faFilePdf;
  //     case mimeType.includes("image"):
  //       return faFileImage;
  //     case mimeType.includes("word"):
  //       return faFileWord;
  //     case mimeType.includes("excel"):
  //       return faFileExcel;
  //     case mimeType.includes("text"):
  //       return faFileAlt;
  //     default:
  //       return faFile; // Generic file icon
  //   }
  // };

  const getCustomIconForFileType = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase(); // Extract file extension

    const iconMap = {
      pdf: "/icons/pdf.png",
      doc: "/icons/doc.png",
      docx: "/icons/doc.png",
      xls: "/icons/excel.png",
      xlsx: "/icons/excel.png",
      ppt: "/icons/ppt.png",
      pptx: "/icons/ppt.png",
      jpg: "/icons/jpg.png",
      jpeg: "/icons/jpeg.png",
      png: "/icons/png.png",
      gif: "/icons/gif.png",
      html: "/icons/html.png",
      css: "/icons/css.png",
      js: "/icons/unknown.png",
      zip: "/icons/zip.png",
      rar: "/icons/rar.png",
      exe: "/icons/exe.png",
      avi: "/icons/avi.png",
      mp3: "/icons/mp3.png",
      mp4: "/icons/mp4.png",
      psd: "/icons/psd.png",
      txt: "/icons/txt.png",
      default: "/icons/unknown.png",
    };

    return iconMap[extension] || iconMap["default"];
  };

  const toggleAccordion = (index) => {
    setExpandedRows((prevState) => {
      const isExpanded = prevState[index];
      if (!isExpanded) {
        fetchLearnerResources(moduleList[index].modDetails.code, index);
      }
      return {
        ...prevState,
        [index]: !isExpanded,
      };
    });
  };

  // Wrapper function to decide which format function to use
  const getOutcomeOrStatus = (proposedOutcome, statusID) => {
    if (
      proposedOutcome === 0 ||
      proposedOutcome === null ||
      proposedOutcome === undefined ||
      proposedOutcome === ""
    ) {
      return formatProposedStatus(statusID);
    }
    return formatProposedOutcome(proposedOutcome);
  };

  const formatProposedOutcome = (outcome) => {
    switch (outcome) {
      case 20:
        return `COMPLETED`;
      case 30:
        return "IN PROGRESS";
      case 40:
        return "WITHDRAWN";
      case 51:
        return "RPL APPROVED";
      case 52:
        return "RPL DENIED";
      case 60:
        return `CREDIT TRANSFERRED`;
      case 61:
        return "SUPERSEDED";
      case 70:
        return "IN PROGRESS";
      case 81:
        return "COMPLETED";
      case 82:
        return "WITHDRAWN";
      case 85:
        return "NOT YET STARTED";
      case 90:
        return "IN PROGRESS";
      default:
        return `N/A`;
    }
  };

  const formatProposedStatus = (outcome) => {
    switch (outcome) {
      case 1:
        return `NOT YET ENROLLED`;
      case 2:
      case 3:
      case 4:
        return `ENROLLED`;
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
        return `COMPETENT`;
      case 10:
        return `WITHDRAWN`;
      case 11:
        return `UNKNOWN`;
      case 13:
        return `COMPETENT`;
      case 14:
        return `COMPLETED`;
      default:
        return "N/A";
    }
  };

  const formatButton = (proposedOutcome, statusID) => {
    let outcome = proposedOutcome;

    if (
      proposedOutcome === 0 ||
      proposedOutcome === null ||
      proposedOutcome === undefined ||
      proposedOutcome === ""
    ) {
      outcome = statusID;
    }

    switch (outcome) {
      case 1:
        return { text: "NOT AVAILABLE", disabled: true };
      case 2:
      case 3:
      case 4:
        return { text: "START ASSESSMENT", disabled: false };
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
        return { text: "COMPLETED", disabled: true };
      case 10:
      case 11:
        return { text: "NOT AVAILABLE", disabled: true };
      case 13:
      case 14:
      case 20:
        return { text: "COMPLETED", disabled: true };
      // Don't remove this case as for some reason case 30 has an issue and won't render
      case 29:
        return { text: "CONTINUE", disabled: false };
      case 30:
        return { text: "CONTINUE", disabled: false };
      case 40:
        return { text: "CONTACT TRAINER", disabled: true };
      case 51:
        return { text: "COMPLETED", disabled: true };
      case 52:
        return { text: "CONTACT TRAINER", disabled: true };
      case 60:
        return { text: "COMPLETED", disabled: true };
      case 61:
        return { text: "Contact Trainer", disabled: false };
      case 70:
        return { text: "Assessment", disabled: false };
      case 81:
        return { text: "COMPLETED", disabled: true };
      case 82:
        return { text: "CONTACT TRAINER", disabled: true };
      case 85:
        return { text: "START ASSESSMENT", disabled: false };
      case 90:
        return { text: "CONTINUE ASSESSMENT", disabled: false };
      default:
        return { text: "NOT AVAILABLE", disabled: true };
    }
  };

  // *** Dont delete *** //
  const handleNavigate = (
    modID,
    apprenticeModsId,
    certID,
    certCode,
    modCode,
    modName,
    classId
  ) => {
    // console.log("classId at handleNavigate:", classId);

    const jsonObject = {
      modID,
      apprenticeModsId,
      certID,
      certCode,
      certName: "Not declared in Classroom: handleNavigate()", // This is a placeholder
      modCode,
      modName,
      classId,
    };

    // Navigate with State method
    navigate("/study/classroom/assessment", {
      state: { jsonObject },
      replace: true,
    });
  };

  function formatDate(dateString) {
    if (!dateString) {
      return "Not yet completed";
    }

    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    let formattedDate = date.toLocaleDateString("en-GB", options); // Example: "7 May 1984"

    // Extract day number to append the ordinal suffix
    const day = date.getDate();
    const suffix =
      ["th", "st", "nd", "rd"][((day % 100) - 20) % 10] ||
      ["th", "st", "nd", "rd"][day % 100] ||
      "th";

    // Replace the day number with the number+suffix
    formattedDate = formattedDate.replace(day, `${day}${suffix}`);

    return `${formattedDate}`;
  }

  const fetchCertDetails = async (certID) => {
    const endpoint = "student/fetch_cert";
    const requestBody = { certID };

    fetchApi(endpoint, requestBody)
      .then((response) => {
        switch (true) {
          case response.status >= 200 && response.status < 300:
            // handleData(response.responseData);
            setCertCode(response.responseData.data[0].code);
            setMsgCode(0);
            break;
          default:
            setMsg(response.msg);
            setMsgCode(4);
            break;
        }
      })
      .catch((error) => {
        console.error("Fetch failed or fetch function is unreachable:", error);
        setMsg(
          "An unexpected error occurred. Contact your administrator" + error
        );
        setMsgCode(4);
      });

  };

  const getDescription = (description) => {
    const cleanedDescription =
      typeof description === "string"
        ? description || "No description available"
        : "Description format incompatible";
    return cleanedDescription;
  };

  const fetchLearnerResources = async (modCode, rowIndex) => {
    try {
      const response = await fetchApi("student/resources", { modCode });
      if (response.status >= 200 && response.status < 300) {
        const resources = response.responseData.files;
        setModuleList((prev) =>
            prev.map((module, idx) =>
                idx === rowIndex ? { ...module, resources } : module
            )
        );
      }
    } catch (err) {
      console.error("Error fetching learner resources:", err);
    }
  };

  //////////////////////////////////////////  RENDER  //////////////////////////////////////////
  if (window.innerWidth < 1000) {
    return (
      <div>
        <Bluey />
        {/* <div className="classroom-btn-container">
          <button
            className="btn-mobile"
            style={{ display: !oldWorkflowData.nextForm ? "none" : "" }}
            disabled={!oldWorkflowData.currentForm}
            onClick={handleWorkflowStart}
          >
            {oldWorkflowData.workflowName}
          </button>
        </div> */} {/* <SpacerDiv rem={4} />
        {workflows.map((item, index) => (
          <ToDoCard 
            key={index} 
            status={item.status} 
            priority={item.priority}
            content={item.instructions}
            link={item.link}
            complete={item.complete}
            allPriorities={workflows.map(w => w.priority)}
          />
        ))}
        */}


        {moduleList
          .sort((a, na) => {
            const isA = !formatButton(a.outcome, a.statusID).disabled;
            const isNa = !formatButton(na.outcome, na.statusID).disabled;
            return isNa - isA;
          })
          .map((row, index) => {
            const status = getOutcomeOrStatus(row.outcome, row.statusID);
            return (
              <ClassroomCard
                key={index}
                first={index === 0 && !workflowData.nextForm}
                code={row.modDetails.code}
                name={row.modDetails.name}
                status={status}
                description={getDescription(row.modDetails.unitDescriptor)}
                startDate={formatDate(row.actualStartDate) || "N/A"}
                endDate={formatDate(row.actualEndDate) || "N/A"}
                available={!formatButton(row.outcome, row.statusID).disabled}
                buttonText={formatButton(
                  row.outcome,
                  row.statusID
                ).text.toLowerCase()}
                onClick={() =>
                  handleNavigate(
                    row.modID,
                    row.id,
                    row.certID,
                    certCode,
                    row.modDetails.code,
                    row.modDetails.name,
                    classId
                  )
                }
              />
            );
          })}
      </div>
    );
  } else {
    return (
      <div>
        <Bluey />
        <div className='classroom-btn-container'>
            <button 
                className='btn-primary'
                style={{ display: !workflowData.nextForm ? 'none' : ''}}
                disabled={!workflowData.currentForm}
                onClick={handleWorkflowStart}
            >
                {workflowData.workflowName}
            </button>
        </div>
        <div className={"page-common"} style={{ zIndex: 1 }}>
          <div
            style={{
              width: "100%",
              padding: 15,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row>
              <Col
                xs={8}
                md={8}
                xl={8}
                style={{
                  marginTop: 15,
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <h1 className="classroom-header">{certificate}</h1>
              </Col>
              <Col
                  xs={4}
                  md={4}
                  xl={4}
                  style={{
                    marginTop: 15,
                    textAlign: "right",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}>
                <button
                    className={'btn-primary'}
                    onClick={() =>
                        navigate("/study/classroom(LIVE)", {
                          state: {
                            classId,
                            certName: certificate },
                          replace: true,
                        })}
                >Join Live Classroom</button>
              </Col>
            </Row>

            <Row
              style={{
                width: "100%",
                margin: "20px auto",
                textAlign: "start",
                padding: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <table style={{ width: "100%", padding: 25 }}>
                <thead>
                  <tr className="classroom-header-row">
                    <th>Code</th>
                    <th>Module Name</th>
                    <th style={{ textAlign: "center" }}>Status</th>
                    <th style={{ textAlign: "center" }}>Actions</th>
                  </tr>
                </thead>
                <tbody className="classroom-desktop">
                  {moduleList.map((row, index) => (
                    <React.Fragment key={index}>
                      <tr
                        onClick={() => toggleAccordion(index)}
                        className={index % 2 === 0 ? "dark-grey" : "light-grey"}
                      >
                        <td>{row.modDetails.code}</td>
                        <td>{row.modDetails.name}</td>
                        <td style={{ textAlign: "center" }}>
                          {getOutcomeOrStatus(row.outcome, row.statusID)}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {(() => {
                            const buttonInfo = formatButton(
                              row.outcome,
                              row.statusID
                            );
                            return (
                              <button
                                onClick={() =>
                                  handleNavigate(
                                    row.modID,
                                    row.id,
                                    row.certID,
                                    certCode,
                                    row.modDetails.code,
                                    row.modDetails.name,
                                    classId
                                  )
                                }
                                className="btn-table"
                                disabled={buttonInfo.disabled}
                                style={{
                                  cursor: buttonInfo.disabled
                                    ? "not-allowed"
                                    : "pointer",
                                  backgroundColor: buttonInfo.disabled
                                    ? "#b7b7b7"
                                    : "#393939",
                                  color: buttonInfo.disabled
                                    ? "#666666"
                                    : "#ffffff",
                                }}
                              >
                                {buttonInfo.text}
                              </button>
                            );
                          })()}
                        </td>
                      </tr>

                      {expandedRows[index] && (
                          <tr className="no-hover">
                            <td colSpan={4} style={{ paddingTop: 0, padding: 0 }}>
                              <div style={{}}>
                                <div style={{ flex: 1 }}>
                                  <p>
                                    <strong>Description:</strong>
                                  </p>
                                  <p>{row.modDetails.unitDescriptor}</p>
                                  <p>
                                    <strong>Start Date:</strong>
                                  </p>
                                  <p>{formatDate(row.actualStartDate)}</p>
                                  <p>
                                    <strong>Completion Date:</strong>
                                  </p>
                                  <p>{formatDate(row.actualEndDate) || "N/A"}</p>

                                  {/* Learner Resources Section */}
                                  <div style={{marginTop: "15px"}}>
                                    <p>
                                      <strong>Learner Resources:</strong>
                                    </p>

                                    <div className="learner-resources">
                                      {(row.resources && row.resources.length > 0) ? (
                                          row.resources.map((resource, index) => (
                                              <div className="resource-item" key={index}>
                                                <a href={resource.link} target="_blank" rel="noopener noreferrer">
                                                  <img
                                                      src={getCustomIconForFileType(resource.fileName)}
                                                      alt={resource.fileName}
                                                      className="resource-icon"
                                                  />
                                                  <p className="resource-name">{resource.fileName}</p>
                                                </a>
                                              </div>
                                          ))
                                      ) : (
                                          <p>No resources available.</p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

// =============================== To Do =================================== //

// =============================== Bugs =================================== //
