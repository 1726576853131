import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useHasRole, useFetchApi } from '../CustomHooks'
import spud from "../Images/spud.png";

import "./Styles/eventViewer.css";

const environment = process.env.REACT_APP_ENV;

export default function EventViewer() {
  const [eventID, setEventID] = useState("");
  const [eventData, setEventData] = useState(null);
  const [isSpuddy, setIsSpuddy] = useState(false);

  const isDev = useHasRole(['Developer']);
  const navigate = useNavigate();
  const fetchApi = useFetchApi();

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  if (!isDev) {
    return (
        <div className="not-allowed-container">
            <h1>Unauthorized</h1>
            <h1>You must be a developer to enter</h1>
            <div className="event-btn-container">
                <button className='btn-primary' onClick={() => navigate('/')}>Take me back to safety</button>
            </div>
        </div>
    );
  }



  const handleSearch = async () => {
    if (eventID === "") {
      setIsSpuddy(true);
      return;
    } else {
      setIsSpuddy(false);
    }
  
    try {
      const result = await fetchApi("errors/getEvent", { eventId: eventID });
  
      if (result.status >= 200 && result.status < 300) {
        setEventData(result.responseData);
      } else {
        console.warn(result.msg); // Optionally show warning
      }
    } catch (error) {
      console.error("Fetch failed:", error);
    }
  };

  return (
    <div className="event-viewer-container">
      <h1 className="event-viewer-header">Event Viewer</h1>{" "}
      <div className="event-btn-container">
        <input
          className="event-input"
          type="text"
          value={eventID}
          onChange={(e) => setEventID(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Enter event ID..."
        />
        <button className="btn-primary" onClick={handleSearch} style={{ width: '100%', margin: '1rem 0 0 0'}}>
          Find me some errors !
        </button>
      </div>
      <div className="event-container">
        {eventData && (
          <div>
            <h2>Event Details: <span style={{ color: '#955CCE'}}>{eventID}</span></h2>{" "}
            <div>
              {Object.entries(eventData).map(([key, value]) => (
                <p
                  className="event-details"
                  key={key}
                >
                  <strong>{key.replace(/([A-Z])/g, " $1").trim()}: </strong>
                  {value !== null ? value.toString() : "N/A"}
                </p>
              ))}
            </div>
          </div>
        )}
        {isSpuddy && (
          <div>
            <h1 className="spud-msg">You are a ...</h1>
            <img src={spud} alt="spud" className="spud" />
            <h1 className="spud-msg">... enter an Event Id and try again</h1>
          </div>
        )}
      </div>
    </div>
  );
}
