interface LoginParams {
  username: string;
  password: string;
  accessLevel: number;
}

export const handleLegacyLogin = async ({ username, password, accessLevel }: LoginParams): Promise<void> => {
  let section: string = accessLevel < 4 ? "FRONTEND" : "BACKEND";

  try {
    const response: Response = await fetch(
      "https://tp.edu.au/portal/tpro-files/backend/login-ajax.php",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          command: "LOGIN",
          username,
          password,
          section,
        }),
        credentials: "include",
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error logging into the legacy portal:", error);
  }
};
