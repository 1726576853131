import React from 'react';
import { MobileModal } from 'Components';
import LoginComponent from '../LoginComponent';

interface RefreshLoginProps {
    isOpen: boolean;
    handleClose: () => void;
    onLoginSuccess: () => void; 
}

const RefreshLogin: React.FC<RefreshLoginProps> = ({ isOpen, onLoginSuccess }) => {

    if (!isOpen) return null;

    return (
        <MobileModal 
            isOpen={isOpen}
            children={
                <LoginComponent 
                    isModal={true} 
                    onLoginSuccess={onLoginSuccess}
                    closeButtonLabel="LEAVE"
                />
            }
            heading="Session Expired"
            onClose={onLoginSuccess}
            withButton={false}
            className="refresh-modal"
        />
    );
};


export default RefreshLogin;