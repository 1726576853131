import React from "react";
import "./Styles/textBox.scss";

interface TextBoxProps {
  questionLength: number | null;
  value?: string;
  /* eslint-disable-next-line no-unused-vars */
  onChange?: (value: string) => void;
  editable?: boolean;
  style?: React.CSSProperties;
  className?: string;
  placeholder?: string;
}

const TextBox: React.FC<TextBoxProps> = ({ 
  questionLength, 
  value,
  onChange, 
  editable = true, 
  style,
  className,
  placeholder,
 }) => (
  <div className={`textbox-container ${className}`}>
    <textarea
      className={`size-${questionLength} custom-textbox ${className}`}
      maxLength={questionLength || undefined}
      value={value}
      onChange={(e) => onChange && onChange(e.target.value)}
      style={style}
      readOnly={!editable}
      placeholder={placeholder || ""}
    />
  </div>
);

export default TextBox;
