import { Bluey, ContactFooter, SpacerDiv, Alert } from 'Components';
import React, { useState } from 'react';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useFetchApi, useUserDeets } from 'CustomHooks';
import { NotificationPayload, NotificationResponse, SendNotification } from 'Services/SendNotification';
import { useNavigate } from 'react-router-dom';
import * as CONST from '../Constants/constants';

interface ContactFormProps {
    setMsg: React.Dispatch<React.SetStateAction<string>>;
    setMsgCode: React.Dispatch<React.SetStateAction<0 | 1 | 2 | 3 | 4>>;
};

const ContactForm: React.FC<ContactFormProps> = ({ setMsg, setMsgCode}) => {
    const { executeRecaptcha } = useGoogleReCaptcha(); 
    const fetchApi = useFetchApi();
    const user = useUserDeets();
    const navigate = useNavigate();
    
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        message: '',
        inquiryType: ''
    });

    const options = [
        'General Enquiry',
        'Technical Support',
        'General Support',
        'Courses and Training',
        'Complaints and Appeals',
    ];

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!executeRecaptcha) {
            setMsg('Don\'t be a bot ... your request has been blocked.');
            setMsgCode(3);
            console.warn("reCAPTCHA not ready.");
            return;
        }

        // Run reCAPTCHA & grab token
        // const token = await executeRecaptcha("contact_form");

        const payload = {
            user,
            subject: `New Inquiry from ${formData.firstName} ${formData.lastName}`,
            templateId: 1000,
            to: CONST.ADMIN_EMAIL,
            body: formData,
            type: "email" as NotificationPayload['type'],
        }; 
      
        const result = (await SendNotification(fetchApi, payload)) as NotificationResponse;

        setMsg(result.msg);
        setMsgCode(result.msgCode);

        if (result.success) {
            navigate('/');
        }
    };

    // Requried fields check
    const requiredFields = Object.values(formData).every(field => field.trim() !== ""); 

    type FormField = 'firstName' | 'lastName' | 'email' | 'message' | 'inquiryType';

    const isFieldInvalid = (field: FormField) => formData[field].trim() === "";


    return (
        <div className='general-form-container'>
            <form onSubmit={handleSubmit} className='flex-container column'>
                <input
                    className={`inputBox ${isFieldInvalid("firstName") ? "required" : ""}`}
                    type='text'
                    name='firstName'
                    placeholder='First Name'
                    required
                    value={formData.firstName}
                    onChange={handleChange}
                />
                <input
                    className={`inputBox ${isFieldInvalid("lastName") ? "required" : ""}`}
                    type='text'
                    name='lastName'
                    placeholder='Last Name'
                    required
                    value={formData.lastName}
                    onChange={handleChange}
                />
                <input
                    type="email"
                    name="email"
                    className={`inputBox ${isFieldInvalid("email") ? "required" : ""}`}
                    placeholder="Email Address"
                    required
                    value={formData.email}
                    onChange={handleChange}
                />
                <select 
                    name="inquiryType"
                    className={`inputBox ${isFieldInvalid("inquiryType") ? "required" : ""}`}
                    value={formData.inquiryType}
                    onChange={handleChange}
                    required
                >
                    <option value="" disabled>
                        Select an option
                    </option>
                    {options.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>

                <textarea
                    name='message'
                    placeholder='Your Message' 
                    className={`textarea-form ${isFieldInvalid("message") ? "required" : ""}`}
                    required
                    value={formData.message}
                    onChange={handleChange}
                />

                <SpacerDiv rem={2} />
                <button 
                    type='submit' 
                    className='btn-primary'
                    disabled={!requiredFields}
                >
                    Send Message
                </button>
                <p className='important-text' style={{ height: '20px'}}>
                {requiredFields ? "" : "Fields in this colour are required"}
              </p>
                <p style={{ fontSize: '12px', textAlign: 'center', color: 'gray' }}>
                    This site is protected by reCAPTCHA and the Google&nbsp;
                    <a 
                        href="https://policies.google.com/privacy" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className='link small glowing-underline'
                    >Privacy Policy</a> 
                    &nbsp;and <a 
                        href="https://policies.google.com/terms" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className='link small glowing-underline'
                    >Terms of Service</a> apply.
                </p>
            </form>
        </div>
    );
};

interface ContactProps {
  
}

const Contact: React.FC<ContactProps> = ({}) => {

    const [msg, setMsg] = useState<string>('');
    const [msgCode, setMsgCode] = useState<0 | 1 | 2 | 3 | 4>(0);

    return (
        <GoogleReCaptchaProvider reCaptchaKey={CONST.RECAPTCHA_FE_KEY}>
            <Alert msg={msg} msgCode={msgCode} timeout={2000} />
            <Bluey />
            <SpacerDiv rem={4} />
            <h2>Contact Us:</h2>
            <ContactForm setMsg={setMsg} setMsgCode={setMsgCode} />
            <ContactFooter />
        </GoogleReCaptchaProvider>
    );
};

export default Contact;
