import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Bluey, SpacerDiv } from 'Components';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

interface PasswordResetProps {}

const PasswordReset: React.FC<PasswordResetProps> = () => {
  const navigate = useNavigate();
  const passwordRef = useRef(null);
  const passwordConfirmRef = useRef(null);

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const togglePasswordVisibility = () => {
    const passwordInput = document.querySelector('[name="password"]');
    if (!showPassword) {
      passwordInput?.setAttribute("type", "text");
    } else {
      passwordInput?.setAttribute("type", "password");
    }
    setShowPassword(!showPassword);
  }

  const togglePasswordConfirmVisibility = () => {
    const passwordInput = document.querySelector('[name="passwordConfirm"]');
    if (!showPasswordConfirm) {
      passwordInput?.setAttribute("type", "text");
    } else {
      passwordInput?.setAttribute("type", "password");
    }
    setShowPasswordConfirm(!showPasswordConfirm);
  }

  const handleSubmitClick = () => {
    console.log('Do a fetch here you lazy bastard!');
    console.log("Log the poor SOB in to the system so they don't have to mess around. But only if the server is happy days 200! Otherwise throw an error cause it ain't my fault ...");
  };

  return (
    <div>
      <Bluey />
      <div className='password-recovery-container' style={{ zIndex: 1, position: 'relative', width: '90vw', margin: '0 auto'}}>
        <SpacerDiv rem={4} />
        <h2>CREATE YOUR NEW PASSWORD</h2>
        <div style={{ width: "100%", position: "relative" }}>
          <input
            className="inputBox login-input"
            type="password"
            name="password"
            placeholder="ENTER NEW PASSWORD"
          //  onChange={handleChange}
            ref={passwordRef}
            title="Ensure your password has at least 8 characters including 1 uppercase, 1 lowercase, 1 number, and 1 special character"
          />
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            onClick={togglePasswordVisibility}
            className="password-toggle"
          />
        </div>
       
        <SpacerDiv rem={1} />

        <div style={{ width: "100%", position: "relative" }}>
          <input
            className="inputBox login-input"
            type="password"
            name="passwordConfirm"
            placeholder="CONFIRM NEW PASSWORD"
          //  onChange={handleChange}
            ref={passwordConfirmRef}
            title="Ensure your password has at least 8 characters including 1 uppercase, 1 lowercase, 1 number, and 1 special character"
          />
          <FontAwesomeIcon
            icon={showPasswordConfirm ? faEyeSlash : faEye}
            onClick={togglePasswordConfirmVisibility}
            className="password-toggle"
          />
        </div>
       
        <SpacerDiv rem={4} />
      

        <div className='flex-container'>
          <button 
            className="btn-primary" 
            type="submit"
            onClick={handleSubmitClick}
          >
            RESET
          </button>
          <button
            className="btn-primary"
            type="button"
            onClick={ () => navigate(-1) }
          >
            GO BACK
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;