import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhoneAlt, faEnvelope, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './Styles/contactFooter.scss';

interface FooterProps {
  withSocials?: boolean;
  extraText?: string;
}

const Footer: React.FC<FooterProps> = () => {
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [showHelp, setShowHelp] = useState<boolean>(false);

  const handleHelpClick = () => {
    setShowHelp(!showHelp);
    console.log('help clicked');
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
      setShowHelp(false);
    }
  };

  useEffect(() => {
    if (showHelp) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showHelp]);

  return (
    <footer>
      <div className='contact-container'>
        <div className='contact-icons'>
          <div className='help-icon' onClick={handleHelpClick} style={{ fontSize: '50px !important'}}>
            <FontAwesomeIcon className='contact-icon' icon={faInfoCircle} id='help'/>
            <p className='desktop-only icon-text'>Helpful Links</p>
          </div>
          {showHelp && (
            <div className='help-links' ref={dropdownRef}>
              <p onClick={() => window.open('https://tp.edu.au/login', '_blank', 'noopener noreferrer')}>Student Information Booklet</p>
              <p onClick={() => window.open('https://tp.edu.au/barcode/', '_blank', 'noopener noreferrer')}>Barcode Verification</p>
              <p onClick={() => window.open('https://tp.edu.au/login', '_blank', 'noopener noreferrer')}>Audit Results</p>
              <p onClick={() => window.open('https://tp.edu.au/complaints-and-appeals/', '_blank', 'noopener noreferrer')}>Complaints and Appeals</p>
              <p onClick={() => window.open('https://tp.edu.au/privacy-policy/', '_blank', 'noopener noreferrer')}>Privacy Policy</p>
              <p onClick={() => window.open('https://tp.edu.au/refund-policy/', '_blank', 'noopener noreferrer')}>Refund Policy</p>
              <p onClick={() => navigate('/feedback')}>Feedback</p>
              <p onClick={() => window.open('https://tp.edu.au/support-services/', '_blank', 'noopener noreferrer')}>Support Services</p>
            </div>
          )}
          <a 
            href='https://www.google.com/maps/place/LVL+4%2F54+Jephson+St,+Toowong+QLD+4066/@-27.4846012,152.9883911,17z/data=!3m1!4b1!4m6!3m5!1s0x6b9150ecb3ba4bb1:0xbfb2e0bb7d1d3cd1!8m2!3d-27.484606!4d152.990966!16s%2Fg%2F11s99yfyfl?entry=ttu&g_ep=EgoyMDI0MTAyOS4wIKXMDSoASAFQAw%3D%3D' 
            target='_blank' 
            rel="noopener noreferrer" 
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <FontAwesomeIcon icon={faMapMarkerAlt} id='location' />
            <p className='desktop-only icon-text'>4/54 Jephson St, Toowong QLD 4066</p>
          </a>
          <a 
            href="tel:1300872461" 
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <FontAwesomeIcon icon={faPhoneAlt} id='phone' />
            <p className='desktop-only icon-text'>1300 872 461</p>
          </a>
          <div 
            onClick={() => window.open('https://new.tp.edu.au/contact/', '_blank', 'noopener noreferrer')}
          >
            <FontAwesomeIcon icon={faEnvelope} id='email' />
            <p className='desktop-only icon-text'>admin@tp.edu.au</p>
          </div>
        </div>
      <p className='mobile-only branding'>Training Professionals Pty Ltd</p>
      </div>
    </footer>
  );
};

export default Footer;
