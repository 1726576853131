import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useVerifyAccessToken } from "CustomHooks";

const TestComponent = () => {
  const { isVerified, error, reverify } = useVerifyAccessToken([]);
  const navigate = useNavigate();

  // Automatically handle redirection based on token verification
  useEffect(() => {
    if (isVerified === false) {
      navigate("/login"); // Redirect if token is invalid
    }
  }, [isVerified, navigate]);

  return (
    <div>
      <h1>Token Verification</h1>
      <p>Status: {isVerified === null ? "Checking..." : isVerified ? "Valid" : "Invalid"}</p>
      {error && <p>Error: {error}</p>}

      <button onClick={reverify}>Re-Verify Token</button>
    </div>
  );
};

export default TestComponent;
