import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../State/Reducers';
import { Alert } from 'Components';
import { useLogout } from 'CustomHooks';

interface ProtectedRouteProps {
  requiredLevel: number;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ requiredLevel }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [isRehydrating, setIsRehydrating] = useState(true); // NEW: Tracks state rehydration
  const accessLevel = useSelector((state: RootState) => state.user.accessLevel);
  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const navigate = useNavigate();
  const { logout } = useLogout();

  useEffect(() => {
    if (loggedIn !== undefined && accessLevel !== undefined) {
      setIsRehydrating(false); // Once Redux has rehydrated, stop waiting
    }
  }, [loggedIn, accessLevel]);

  useEffect(() => {
    if (isRehydrating) return; // Prevent early logout due to state being undefined

    if (!loggedIn || accessLevel === null || accessLevel === undefined) {
      // logout();
      setTimeout(() => navigate('/login'), 0); // Ensures proper redirection
    } else if (accessLevel < requiredLevel) {
      setShowAlert(true);
    }
  }, [accessLevel, requiredLevel, loggedIn, logout, navigate, isRehydrating]);

  if (isRehydrating) {
    return null; // Wait until Redux state is fully loaded
  }

  if (!loggedIn || accessLevel === null || accessLevel === undefined) {
    return null; // Prevent rendering while redirecting
  }

  if (accessLevel < requiredLevel) {
    return (
      <>
        {showAlert && (
          <Alert
            msg="You do not have the required access level to view this page."
            msgCode={3}
            onClose={() => {
              setShowAlert(false);
              navigate(-1);
            }}
            timeout={2000}
          />
        )}
      </>
    );
  }

  return <Outlet />;
};

export default ProtectedRoute;
