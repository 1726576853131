import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { showRefreshLogin } from 'State/Reducers/refreshLoginSlice';
import { clearEncryptionKey, setEncryptionKey } from 'State/Reducers/keySlice';
import { useUserField } from './useUserDeets';
import CryptoJS from 'crypto-js';
import { MAX_FAILURES, HEARTBEAT_INTERVAL, KEY_REFRESH_INTERVAL } from 'Constants/constants';
import useFetchApi from 'Services/FetchApi';

interface HeartbeatResponse {
  error: string;
  key: string;
};

export const useHeartbeat = () => {
  const fetchApi = useFetchApi();
  const [failures, setFailures] = useState(0);
  const dispatch = useDispatch();
  const heartbeatInterval = useRef<NodeJS.Timeout | null>(null);
  const isLoggedIn = useUserField('loggedIn');
  const lastKeyRefresh = useRef<number>(Date.now());

  // REMOVE: This is for testing only the key will be generated by the server 
  const generateRandomKey = () => {
    const randomKey = CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Hex); // 128-bit key
    return randomKey;
  };

  const heartbeat = useCallback(async () => {
    try {
      const response = await fetchApi('system/heartbeat'); 
      const responseData = (response.responseData ?? {}) as HeartbeatResponse;
      
      if (response.status === 200) {
        // Is it time to refresh the key?
        if (Date.now() - lastKeyRefresh.current > KEY_REFRESH_INTERVAL) {
          const newKey = responseData.key;
          dispatch(setEncryptionKey(newKey));
          lastKeyRefresh.current = Date.now();
        }
  
        setFailures(0);
        console.log('%cYour heart is beating', 'color: green');
      } else {
        setFailures((prevFailures) => {
          const newFailures = prevFailures + 1; 
          console.error('Your heart has stopped', newFailures)
          if (newFailures >= MAX_FAILURES) {
            dispatch(showRefreshLogin());
            if (heartbeatInterval.current) {
              clearInterval(heartbeatInterval.current);
            }
          }
          return newFailures;
        });
      }   
    } catch (error) {
      setFailures((prevFailures) => {
        const newFailures = prevFailures + 1; 
        console.error('Your heart has stopped', newFailures)
        if (newFailures >= MAX_FAILURES) {
          dispatch(showRefreshLogin());
          if (heartbeatInterval.current) {
            clearInterval(heartbeatInterval.current);
          }
        }
        return newFailures;
      });
    }
  }, [dispatch]);

  const restartHeartbeat = useCallback(() => {
    if (heartbeatInterval.current) {
      clearInterval(heartbeatInterval.current);
    }
    heartbeatInterval.current = setInterval(heartbeat, HEARTBEAT_INTERVAL);
  }, [heartbeat]);


  useEffect(() => {
    if (isLoggedIn) {
      restartHeartbeat();
    } else {
      if (heartbeatInterval.current) {
        clearInterval(heartbeatInterval.current);
        dispatch(clearEncryptionKey());
      }
    }

    return () => {
      if (heartbeatInterval.current) {
        clearInterval(heartbeatInterval.current);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, restartHeartbeat]);

  return { failures };
};
