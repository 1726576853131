import * as CONST from "../Constants/constants";

export interface NotificationPayload {
  user: any;
  subject: string;
  templateId: number;
  to: string;
  body: any;
  type: 'email' | 'sms' | 'pw_reset_email' | 'pw_reset_sms';  
}

export interface NotificationResponse {
  success: boolean;
  msg: string;
  msgCode: 0 | 1 | 2 | 3 | 4;
  response?: any;
}


export const SendNotification = async (
  fetchApi: (endpoint: string, data: any) => Promise<any>, // Pass fetchApi instead of calling hook
  payload: NotificationPayload
) => {
  let endpoint;

  switch (payload.type) {
    case "email":
      endpoint = CONST.EMAIL_NOTIFICATIONS;
      break;
    case "sms":
      endpoint = CONST.SMS_NOTIFICATIONS;
      break;
    case "pw_reset_email":
      endpoint = CONST.EMAIL_PW_RESET;
      break;
    case "pw_reset_sms":
      endpoint = CONST.SMS_PW_RESET;
      break;
    default:
      return {
        success: false,
        msg: "Invalid notification type",
        msgCode: 4,
      };
  }

  console.log("Sending notification:", payload);  
  
  try {
    const response = await fetchApi(endpoint, payload);

    if (response && response.status >= 200 && response.status < 300) {
      return {
        success: true,
        msg: "Notification sent successfully.",
        msgCode: 1,
        response,
      };
    } else {
      return {
        success: false,
        msg: "Error sending notification.",
        msgCode: 4,
        response,
      };
    }
  } catch (error) {
    console.error("Error sending notification:", error);
    return {
      success: false,
      msg: "An error occurred while sending the notification.",
      msgCode: 4,
    };
  }
};
