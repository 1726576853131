// /* eslint-disable */
import { useContext, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { ConstLink } from '../ConstLink';
import logo from '../Images/logo4.png';
import { useLogout, useHasRole } from 'CustomHooks';


// Redux
import * as CH from 'CustomHooks';


const Header = () => {
    const navigate = useNavigate();
    const location = useLocation(); 
    const { closeHeaderMenu, toggleMenu, isActive } = useContext(ConstLink);
    const isLoggedIn = CH.useUserField('loggedIn');
    const userName = CH.useUserField('username');
    const { logout } = useLogout();
    const isDev = useHasRole(['Developer']);

    const isActiveRoute = (route) => {
        return location.pathname === route;
    };


    const menuRef = useRef(null);
    const handleLogout = async() => {
       logout();
    }


    const toggleHeaderMenu = () => {
        toggleMenu();
    };

    const navigateTo = (address) => {
        if (window.innerWidth < 900) {
            closeHeaderMenu(); // Close the navigation menu by calling the toggleMenu function
        }
        navigate(address);
    };

    const handleMenuItemClick = () => {
        const checkbox1 = document.getElementById('checkbox1');
        const checkbox2 = document.getElementById('checkbox2');
        const checkbox3 = document.getElementById('checkbox3');

        if (checkbox1) {
            checkbox1.checked = false;
        }

        if (checkbox2) {
            checkbox2.checked = false;
        }

        if (checkbox3) {
            checkbox3.checked = false;
        }
    };


    return (
        <div className="header-overflow">
            <nav>

                <div className="header-container">

                    <section className="menu menu--circle">
                        <input type="checkbox" id="menu__active"/>
                        <label htmlFor="menu__active" className="menu__active">
                            <div className="menu__toggle">
                                <div className="icon">
                                    <img src={logo} style={{width: 80, zIndex: 9999}} alt="roo"/>
                                </div>
                            </div>
                            <input id="checkbox1" type="radio" name="arrow--up" id="degree--up-0" hidden={true}/>
                            <input id="checkbox2" type="radio" name="arrow--up" id="degree--up-1" hidden={true}/>
                            <input id="checkbox3" type="radio" name="arrow--up" id="degree--up-2" hidden={true}/>
                            <div
                                className="menu__listings"
                                ref={menuRef}
                            >
                                <ul className="circle">
                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                                <a draggable={false} onClick={() => navigateTo('/portal/administration/setup')} className="button"><i className="fa fa-cog"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                                <a draggable={false} onClick={() => {navigateTo('/'); handleMenuItemClick();}} className="button"><i
                                                    className="fa fa-home"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                            <a draggable={false} onClick={() => {navigateTo('/study/my-profile'); handleMenuItemClick();}} className="button"><i className="fa fa-user"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                            <a draggable={false} onClick={() => {navigateTo('/chat'); handleMenuItemClick();}} className="button"><i className="fa fa-commenting"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                            <a draggable={false} onClick={() => {navigateTo('/files'); handleMenuItemClick();}} className="button"><i
                                                    className="fa fa-folder-open"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                            <a draggable={false} onClick={() => {navigateTo('/contact'); handleMenuItemClick();}} className="button"><i
                                                    className="fa fa-envelope"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                            <a draggable={false} onClick={() => {navigateTo('/calendar'); handleMenuItemClick();}} className="button"><i
                                                    className="fa fa-calendar"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                            <a draggable={false} onClick={() => {navigateTo('/cloud-storage'); handleMenuItemClick();}} className="button"><i className="fa fa-cloud"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                            <a draggable={false} onClick={() => {navigateTo('/study/my-profile'); handleMenuItemClick();}} className="button"><i
                                                    className="fa fa-id-card"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                                <a draggable={false} onClick={() => window.location.href('https://tp.edu.au/portal/login/')} className="button"><i
                                                    className="fa fa-history"></i></a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                            <div className="menu__arrow menu__arrow--top">
                                <ul>
                                    <li>
                                        <label htmlFor="degree--up-0">
                                            <div className="arrow"></div>
                                        </label>
                                        <label htmlFor="degree--up-1">
                                            <div className="arrow"></div>
                                        </label>
                                        <label htmlFor="degree--up-2">
                                            <div className="arrow"></div>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </label>
                    </section>

                    <div className="navbar-container" >
                    {isActiveRoute('/error') && <span style={{ color: 'white', alignSelf: 'center', fontSize: '24px'}}>Oh Oh ...looks like Bluey took the wrong route ...</span>}

                        {isLoggedIn && (

                            <nav className="navbar navbar-expand-lg">

                                <button className="navbar-toggler header-menu-toggle" type="button"
                                        onClick={toggleHeaderMenu}
                                >
                                    <span style={{color: 'white'}}>MENU</span>
                                </button>


                                <div className={`collapse navbar-collapse ${isActive ? 'show' : ''}`}>

                                    <ul className="navbar-nav mb-2 mb-lg-0" style={{}}>

                                        <li>
                                            <button className="customSpacer"></button>
                                        </li>

                                        <li>
                                            <button className="customToggle" 
                                                onClick={() => navigateTo('/study')} 
                                                style={{color: isActiveRoute('/study') ? '#d0d0d0' : ''}}
                                            >
                                                STUDY
                                            </button>

                                            {/* <button 
                                                className="customToggle" 
                                                onClick={() => navigateTo('/study/enrolment')}
                                                style={{color: isActiveRoute('/study/enrolment') ? '#d0d0d0' : ''}}
                                            >
                                                ENROL
                                            </button> */}

                                            <button 
                                                className="customToggle" 
                                                onClick={() => navigateTo('/study/diary')}
                                                style={{color: isActiveRoute('/study/diary') ? '#d0d0d0' : ''}}
                                            >
                                                DIARY
                                            </button>

                                            <button 
                                                className="customToggle" 
                                                onClick={() => navigateTo('/feedback')}
                                                style={{color: isActiveRoute('/feedback') ? '#d0d0d0' : ''}}
                                            >
                                                FEEDBACK
                                            </button>
                                           
                                            <button 
                                                className="customToggle" 
                                                onClick={() => navigateTo('/contact')}
                                                style={{color: isActiveRoute('/contact') ? '#d0d0d0' : ''}}
                                            >
                                                CONTACT
                                            </button>
                                           
                                           {isDev && <button 
                                                className="customToggle" 
                                                onClick={() => navigateTo('/event-viewer')}
                                                style={{color: isActiveRoute('/event-viewer') ? '#d0d0d0' : ''}}
                                            >
                                                EVENT VIEWER
                                            </button>}
                                           
                                           {isDev && <button 
                                                className="customToggle" 
                                                onClick={() => window.open('https://api.tp.edu.au/docs', '_blank')}
                                            >
                                                API DOCS
                                            </button>}
                                        </li>
                                        <li>
                                            <button className="customSpacer"></button>
                                        </li>

                                    </ul>
                                </div>
                            </nav>
                        )}


                        <div className="profile-container"> 

                            <a className="username">
                                {userName}
                            </a>

                            {isLoggedIn &&
                                <a className="header-button">
                                    <i onClick={() => navigateTo('/study/my-profile')}
                                    className="fa fa-user"></i>
                                </a> 
                            }
                            <a className="header-button">
                                {isLoggedIn ? (
                                    <i onClick={handleLogout}
                                       className="fa fa-lock-open"></i>
                                ) : (
                                    <i onClick={() => navigateTo('/login')}
                                       className="fa fa-lock" ></i>
                                )}
                            </a>
                            <a className="header-button">
                                <i onClick={() => navigateTo('')}
                                   className="fa fa-search"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
};


export default Header;
