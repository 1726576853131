import React, { ReactNode, useEffect, useState, useRef } from 'react';
import { useViewport } from 'CustomHooks';
import './Styles/tooltip.scss';

interface TooltipProps {
  content: string | ReactNode;
  children: ReactNode;
  styles?: React.CSSProperties;
  className?: string;
  position?: "top" | "bottom" | "left" | "right";
}

const Tooltip: React.FC<TooltipProps> = ({
  content = 'Did you forget to put something here?',
  children,
  styles,
  className,
  position = "top", // Default position
}) => {
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const isMobile = useViewport().showMobile;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isMobile) return; // Only add event listener on mobile

    const handleOutsideClick = (event: MouseEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isMobile]);

  return (
    <div className="tooltip-wrapper" ref={tooltipRef}>
      <div 
        className="tooltip-trigger"
        onMouseEnter={() => !isMobile && setIsOpen(true)}
        onMouseLeave={() => !isMobile && setIsOpen(false)}
      >
        {children}
      </div>
      {isMobile && (
          <i className="fa-solid fa-lightbulb btn-mobile-tooltip" onClick={() => setIsOpen((prev) => !prev)} />
      )}

      {isOpen && (
        <div className={`tooltip-container tooltip-${position} ${className}`} style={styles}>
          {content}
        </div>
      )}

    </div>
  );
};

export default Tooltip;
