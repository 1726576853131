import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showRefreshLogin: false,
};

const modalSlice = createSlice({
    name: 'refreshLogin',
    initialState,
    reducers: {
        showRefreshLogin: (state) => {
            state.showRefreshLogin = true;
        },
        hideRefreshLogin: (state) => {
            state.showRefreshLogin = false;
        },
    },
});

export const { showRefreshLogin, hideRefreshLogin } = modalSlice.actions;

export default modalSlice.reducer;
